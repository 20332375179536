<template>
  <div class="case-info-widget">
    <div
      v-if="activeTypeCase?.additionalCard"
      class="case-info-widget__first-block"
      :style="{ '--max-width': leftWidth }"
    >
      <SharedCaseDescription is-left-side :left-side-object="leftSideDataObject">
        <template #left-side-title>
          <div class="case-info-widget__image">
            <SharedImage :img="activeTypeCase.additionalCard.title.image" type="png" v-bind="sizeTitle"></SharedImage>
            <SharedImage
              v-if="counterImg"
              v-bind="sizeCounterImg"
              :key="String(counterImg)"
              :img="counterImg"
              type="png"
              class="scale-animation"
            ></SharedImage>
          </div>
        </template>

        <template #left-side-description>
          <SharedText v-if="counter" v-bind="optionsTextDescription(activeTypeCase.additionalCard.descriptionColor)">
            Гарантировано скина за одно открытие
          </SharedText>
        </template>
      </SharedCaseDescription>
    </div>

    <div class="case-info-widget__second-block" :style="{ '--max-width': rightWidth }">
      <SharedCaseDescription :is-left-side="false" :right-side-object="rightSideDataObject">
        <template #right-side-title>
          <SharedText v-bind="caseItemLabelTitle">
            {{ props.itemCase?.label }}
          </SharedText>
        </template>

        <template #right-side-subtitle>
          <SharedText v-bind="caseItemLabelSubtitle">
            {{ itemCase.labelData?.text }}
            <!-- Внутри компонента { SharedCaseDescription } уже есть условие, если нет labelData, блок не покажется-->
          </SharedText>
        </template>

        <template #right-side-description>
          <SharedText v-bind="optionsTextDescription(Colors.NEUTRAL.WHITE_75)"> {{ caseDescription }}</SharedText>
        </template>
        <template #right-side_prices>
          <CaseAdditionalWidget :item-case="props.itemCase" />
        </template>
      </SharedCaseDescription>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Property } from 'csstype';
import SharedImage from '~/components/SharedImage/SharedImage.vue';
import SharedCaseDescription from '~/components/SharedCaseDescription/SharedCaseDescription.vue';
import { useSettingInfoCase } from '~/features/cases/composables/useSettingInfoCase';
import type { ICaseInfoWidgetProps } from '~/features/cases/widgets/CaseInfoWidgetLegacy/CaseInfoWidget.types';
import { FontSizes, FontWeights } from '~/types/SharedFont.types';
import { Colors } from '~/constants/colors.constants';
import CaseAdditionalWidget from '~/features/cases/widgets/CaseAdditionalWidget/CaseAdditionalWidget.vue';
import SharedText from '~/components/SharedText/SharedText.vue';
import type { ICaseData } from '~/repository/modules/cases/cases.types';
import type { ISharedTextProps } from '~/components/SharedText/SharedText.types';
import type { TSide } from '~/components/SharedCaseDescription/SharedCaseDescription.types';

const { xl } = useMedia();

const props = withDefaults(defineProps<ICaseInfoWidgetProps>(), {
  itemCase: () => ({}) as ICaseData,
  leftWidth: '560px',
  rightWidth: '560px',
});

// конфигурация мультикаст/мультификс инфы
const { activeTypeCase, counterImg, isTablesAndMoreSize, sizeCounterImg, sizeIconsTypeCase, sizeTitle } =
  useSettingInfoCase(xl);

const counter = {};

// adaptive
const sizeIcons = computed<string>(() => {
  return xl.value ? '112' : '72';
});

// размер заголовка
const sizeTextTitle = computed<string>(() => {
  return xl.value ? FontSizes.LARGE_XL : FontSizes.SMALL;
});

// размер текста рядом с заголовком
const sizeSubTitle = computed<string>(() => {
  return xl.value ? FontSizes.MEDIUM : FontSizes.SMALL_2XS;
});

// размер текста
const sizeDescription = computed<string>(() => {
  return xl.value ? FontSizes.MEDIUM : FontSizes.SMALL_XS;
});

// сокращение описания
const caseDescription = computed<string>(() => {
  const text = props.itemCase?.descriptionData?.full;
  if (text)
    return props.itemCase?.priceData?.price || props.itemCase?.statisticData?.openedCount
      ? GlobalUtils.Text.shortenText(text, 120)
      : GlobalUtils.Text.shortenText(text, 245);
  return '';
});

const leftSideDataObject = computed<TSide>(() => ({
  bg: activeTypeCase.value?.additionalCard?.colorBg,
  image: activeTypeCase.value?.additionalCard?.avatar.image,
  imgHeight: '100%',
  imgWidth: sizeIconsTypeCase.value,
  showImg: isTablesAndMoreSize.value,
}));

const rightSideDataObject = computed<TSide>(() => ({
  bg: props.bg,
  image: { png1: props.itemCase?.imageData?.image || '' },
  imgHeight: sizeIcons.value,
  imgWidth: sizeIcons.value,
  isShowSubtitle: !!props.itemCase.labelData?.text,
  subtitleBackground: props.itemCase.labelData?.background ?? '',
}));

const caseItemLabelTitle = computed<ISharedTextProps>(() => ({
  color: Colors.NEUTRAL.WHITE,
  fontCase: 'upper',
  size: sizeTextTitle.value,
  weight: FontWeights.BLACK,
}));

const caseItemLabelSubtitle = computed<ISharedTextProps>(() => ({
  color: Colors.NEUTRAL.WHITE,
  size: sizeSubTitle.value,
  weight: FontWeights.BOLD,
}));

// функция помощник
const optionsTextDescription = (
  color: Property.Color,
): { color: Property.Color; size: string; weight: FontWeights } => {
  return {
    color,
    size: sizeDescription.value,
    weight: FontWeights.MEDIUM,
  };
};
</script>
<style src="./CaseInfoWidget.scss" scoped lang="scss"></style>
