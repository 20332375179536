import type {
  ISharedButtonNewColorPreset,
  ISharedButtonNewFormPreset,
  TSharedButtonTextPreset,
} from '~/types/SharedButtonNew.types';
import {
  defaultButtonColorPreset,
  defaultMediumButtonTextPreset,
  defaultMiddleButtonFormPreset,
} from '~/components/SharedButtonNew/SharedButtonNew.data';
import { Colors } from '~/constants/colors.constants';
import { FontSizes } from '~/types/SharedFont.types';
import type { ITopPriceOptions } from '~/components/SharedDropItem/SharedDropItem.types';
import { CurrencyName } from '~/constants/currency.constants';

// Кнопки открытия кейса

// Пресеты форм

const openCaseBtnProperties: ISharedButtonNewFormPreset = {
  ...defaultMiddleButtonFormPreset,
  height: '40px',
  media: {
    md: { height: '40px', minWidth: '220px', width: '220px' },
    sm: { height: '40px', minWidth: '344px', width: '344px' },
    xl: { height: '50px', minWidth: '280px', width: '280px' },
    xxl: { height: '50px', minWidth: '280px', width: '280px' },
  },
  minWidth: '344px',
  width: '344px',
};

export const openCaseBtnFormPreset: ISharedButtonNewFormPreset = {
  ...openCaseBtnProperties,
  radius: 0,
};

export const openCaseBtnFormPresetLeft: ISharedButtonNewFormPreset = {
  ...openCaseBtnProperties,
  radius: '0 0 0 8px',
};

export const openCaseBtnFormPresetRight: ISharedButtonNewFormPreset = {
  ...openCaseBtnProperties,
  radius: '0 0 8px 0',
};

// Пресеты цвета
export const defaultOpenCaseFastBtnColorPreset: ISharedButtonNewColorPreset = {
  ...defaultButtonColorPreset,
  color: 'var(--main-color-400)',
  colorHovered: 'var(--main-color-300)',
};
export const defaultOpenCaseColorPreset: ISharedButtonNewColorPreset = {
  ...defaultButtonColorPreset,
  color: 'var(--main-color-200)',
  colorHovered: 'var(--main-color-100)',
};
export const BKOpenCaseFastBtnColorPreset: ISharedButtonNewColorPreset = {
  ...defaultOpenCaseFastBtnColorPreset,
  color: Colors.BK_INTEGRATION.TOP_PRICE_PURPLE,
  colorHovered: Colors.BK_INTEGRATION.TOP_PRICE_PURPLE,
};

export const BKOpenCaseBtnColorPreset: ISharedButtonNewColorPreset = {
  ...defaultOpenCaseFastBtnColorPreset,
  color: 'var(--main-color-200)',
  colorHovered: 'var(--main-color-100)',
};

// Пресеты текста
export const openCaseBtnSmallTextPreset: TSharedButtonTextPreset = {
  ...defaultMediumButtonTextPreset,
  color: Colors.NEUTRAL.WHITE,
  size: FontSizes.MEDIUM,
};

export const openCaseBtnBigTextPreset: TSharedButtonTextPreset = {
  ...defaultMediumButtonTextPreset,
  color: Colors.NEUTRAL.WHITE,
  size: FontSizes.LARGE,
};

// Пресеты для плашки цены предмета в рулетке
export const topPriceBKPreset: ITopPriceOptions = {
  color: Colors.BK_INTEGRATION.TOP_PRICE_PURPLE,
};

export const topPriceBKDemoPreset: ITopPriceOptions = {
  ...topPriceBKPreset,
  currency: CurrencyName.DM,
};
