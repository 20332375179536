import type { Ref } from 'vue';
import type { TDropSize } from '~/features/bk/components/BKCarouselCases/BKCarouselCases.types';

interface IGeneralArg {
  // Родительский элемент карусели, который включает в себя элементы
  carouselElement: Ref<HTMLElement[] | undefined>;
  // Определяющая линия
  carouselLine: Ref<HTMLElement[] | undefined>;
  // Отступы между элементами
  gapInDrop: number;
  // Кол-во элементов
  itemCount: Ref<number>;
  // Кол-во элементов отображаемых с конца до определяющей линии
  itemEndCount: Ref<number>;
  // Ширина карточки внутри карусли
  itemSize: Ref<TDropSize>;
  // делает позицию предмета более рандомной
  randomizePosition: number;
}

// interface IOtherArg {
//   // Граница слева до остановки определяющей стрелки
//   leftBorderDiapason: number;
//   // Граница справа до остановки определяющей стрелки
//   rightBorderDiapason: number;
// }

interface IReturnUseCarouselAlgorithm {
  // Анимация скроллинга карусели
  animationScrollCarousel: () => string;
  multiCarouselAnimation: () => string;
}

// Хук отвечат за анимацию прокрутки рулетки до выбранного элемента
export function useAnimationAlgorithm({
  carouselElement,
  carouselLine,
  itemCount,
  itemEndCount,
  itemSize,
  gapInDrop,
}: IGeneralArg): IReturnUseCarouselAlgorithm {
  // Выполняет вычисления свзяанные с определяющей линией
  const calculatedValuesDefiningLine = (): number => {
    // Координаты определяющей линии
    const coordsDefiningLine = GlobalUtils.DOM.getCoords(carouselLine.value![0]);
    // Ширина определяющей линии
    const widthDefiningLine = coordsDefiningLine.width;
    // Левая граниа расположения определяющей линии
    const leftPointDefiningLine = coordsDefiningLine.x;
    // Сумма левой границы и ширины определяющей линии (чтобы при выборе предмета, полоса оставалась внутри предмета, а не за пределами)
    return widthDefiningLine + leftPointDefiningLine;
  }; // кеш  для разных  анимации
  const animationScrollCarousel = (): string => {
    // Индекс элемента массива, куда будет осущесвтляться подстановка победного элемента
    const idxOfTheWinningItem = itemCount.value - itemEndCount.value;

    // Координаты родителя карточек рулетки
    const coordsParentBlock = GlobalUtils.DOM.getCoords(carouselElement.value![0]);
    // Крайняя левая граница блока родителя карточек
    const leftPointParentBlockElements = coordsParentBlock.x;

    // Сумма ширины линии и её левой крайней точки
    const sumLeftPointDefiningLineWithWidthLine = calculatedValuesDefiningLine();
    // генерация диапозона, чтобы стрелка не была всегда по центру предмета
    // const randomNumber =
    //   Math.floor(randomIntFromInterval(0.4, 0.6) * itemSize.value - leftBorderDiapason) + rightBorderDiapason;
    // Сумма отступов между элементами до элемента под победным индексом ( отступ справа от него не включён)
    const gapRangeToSelectedElement = gapInDrop * (idxOfTheWinningItem - 1);
    // Расстояние от левого края до стрелки, то есть сколько элементов влезает до стрелки слева
    const rangeBetweenLeftPointParentBlockAndDefiningLine =
      sumLeftPointDefiningLineWithWidthLine - leftPointParentBlockElements;

    // Позволяет нам определить расстояние до элемента под нужным индексом в сумме с отступами
    const distanceToTheSelectedItem = itemSize.value * idxOfTheWinningItem + gapRangeToSelectedElement;
    // Насколько до края предмета останавливается рулетка
    const indentRightForItem = 15;
    // Расстояние до скролла учитывает уже видимые элементы от стрелки слева, расстояние до победного элемента, а так-же учтён диапозон остановки стрелки на элементе
    const scrollLength =
      distanceToTheSelectedItem - rangeBetweenLeftPointParentBlockAndDefiningLine - indentRightForItem;
    return `translateX(-${scrollLength}px)`;
  };

  const multiCarouselAnimation = (): string => {
    return `translate(0px, 0px)`;
  };

  return {
    animationScrollCarousel,
    multiCarouselAnimation,
  };
}
