<template>
  <ClientOnly>
    <div class="carousel-cases__main-wrapper">
      <div class="carousel-cases" :style="styleCarouselContainer">
        <div
          v-for="(count, index) in carouselCount"
          v-show="isAnimating || index === 0"
          ref="carousel"
          :key="count"
          :class="{
            'carousel-cases__first-case': index === 0,
          }"
          class="inner"
        >
          <div v-if="index === 0" class="carousel-cases__line" :style="lineStyle"></div>
          <div class="fill-container__top-left-corner" :class="carouselCasesNoLine(index)" :style="cornerStyle"></div>
          <div class="fill-container__top-right-corner" :class="carouselCasesNoLine(index)" :style="cornerStyle"></div>
          <div
            class="carousel-cases__hide-container"
            :class="carouselCasesNoLine(index)"
            :style="hideContainerStyle"
          ></div>
          <div
            ref="carouselLine"
            class="carousel-cases__cases-container_line"
            :class="carouselCasesContainerLine(index)"
          ></div>

          <div ref="carouselWrapper" class="carousel-cases__cases-wrapper" :style="fillContainerStyle">
            <div
              ref="carouselElement"
              :style="carouselCases"
              class="carousel-cases__cases-container"
              :class="{ 'carousel-cases__cases-container-spin': isSpin }"
            >
              <slot v-if="props.cases" name="case" :get-cases="props.cases[index]"></slot>
            </div>
          </div>
          <div class="carousel-cases__line" :style="lineStyle"></div>
        </div>
      </div>
    </div>
  </ClientOnly>
</template>

<script lang="ts" setup>
import type { CSSProperties } from 'vue';
import type { ICarouselCasesProps, IEmitCarouselCases } from './BKCarouselCases.types';
import { useEmitScroll } from './helper.function';
import { carouselTiming, multiCarouselTiming } from './BKCarouselCases.data';
import { Breakpoints } from '~/constants/media.constants';

const { toPx } = GlobalUtils.Converting;
const { proceedCssValue } = GlobalUtils.CSS;

const props = withDefaults(defineProps<ICarouselCasesProps>(), {
  itemCount: 1,
  lineColor: ' linear-gradient(90deg, rgb(51 24 98 / 0%) 0%, #48258a 50%, rgb(51 24 98 / 0%) 100%)',
  paddingWrapper: '16px',
});

const emit = defineEmits<IEmitCarouselCases>();

const { carouselHeight, itemSize, itemCount, itemEndCount } = toRefs(props);
const viewport = useViewport();
const carousel = ref<HTMLElement[]>();
const isAnimating = ref<boolean>(false);
const carouselElement = ref<HTMLElement[]>();
const carouselWrapper = ref<HTMLElement[]>();
const carouselLine = ref<HTMLElement[]>();
const isSpin = ref<boolean>(false);
const gapInDrop = 8;

// улучшить читаемость
const calculateLineWidth = () => {
  return (itemSize.value + gapInDrop) * itemCount.value;
  // return itemSize.value * itemCount.value + (itemCount.value - 1) * gapInDrop;
};

// Функция установки высотка для элементов карусели
const setCarouselHeight = (styles: CSSProperties, num: number): void => {
  if (!carouselHeight?.value) return;
  if (carouselHeight.value && viewport.isGreaterOrEquals(Breakpoints.xl)) {
    styles.height = toPx(carouselHeight.value);
  } else {
    styles.height = toPx(carouselHeight.value + num);
  }
};

const styleCarouselContainer = computed(() => {
  const styles: CSSProperties = {};
  styles.width = props.carouselWidth && proceedCssValue(props.carouselWidth);
  return styles;
});

const carouselCases = computed(() => {
  const properties: CSSProperties = {};

  if (props.itemCount && props.itemSize) {
    properties.width = proceedCssValue(calculateLineWidth());
  }

  return properties;
});

const hideContainerStyle = computed(() => {
  const styles: CSSProperties = {};
  styles.width = props.carouselWidth && proceedCssValue(props.carouselWidth);
  setCarouselHeight(styles, 22);
  return styles;
});

const lineStyle = computed(() => {
  const styles: CSSProperties = {};
  styles.background = props.lineColor;
  styles.width = props.lineWidth && proceedCssValue(props.lineWidth);
  return styles;
});

const cornerStyle = computed(() => {
  const styles: CSSProperties = {};
  if (carouselHeight?.value) {
    styles.height = toPx(carouselHeight?.value + 2);
  }
  styles.width = props.cornerWidth && proceedCssValue(props.cornerWidth);
  return styles;
});

const fillContainerStyle = computed(() => {
  const styles: CSSProperties = {};
  setCarouselHeight(styles, 18);
  if (props.carouselWidth) styles.width = proceedCssValue(props.carouselWidth);
  styles['--padding'] = props.paddingWrapper;
  return styles;
});

// анимация при появлении дополнительных рулеток
function animateOtherRoulets(count: number): void {
  if (count > 1 && isAnimating) {
    // @ts-expect-error TODO remove or refactor
    const { multiCarouselAnimation } = useAnimationAlgorithm({
      carouselElement,
      carouselLine,
      gapInDrop,
      itemCount,
      itemEndCount,
      itemSize,
    });
    const elements: HTMLElement[] | undefined = carousel!.value;
    elements?.forEach((element: HTMLElement, index: number) => {
      if (index !== 0) {
        element.style.transform = `translate(0px, -${index * 100}%)`;
        const animation = element.animate({ transform: multiCarouselAnimation() }, multiCarouselTiming);
        animation.play();
        if (index === elements.length - 1) {
          animation.onfinish = animateCases;
        }
      }
    });
  }
}

const { emitScroll } = useEmitScroll(emit, { gapInDrop, itemSize });

// анимация прокрутки кейсов
function animateCases() {
  const randomizePosition = GlobalUtils.Maths.randomIntFromInterval(0.1, 0.9);
  const { animationScrollCarousel } = useAnimationAlgorithm({
    carouselElement,
    carouselLine,
    gapInDrop,
    itemCount,
    itemEndCount,
    itemSize,
    randomizePosition,
  });

  const elements: HTMLElement[] | undefined = carouselElement.value;
  elements?.forEach((element, index) => {
    const animation = element.animate({ transform: animationScrollCarousel() }, carouselTiming);
    if (index === elements.length - 1) {
      const stopEmit = emitScroll(
        carouselElement.value![0],
        carouselWrapper.value![0],
        itemCount.value,
        carouselLine.value![0],
      );
      animation.onfinish = () => finishAnimation(animation, stopEmit);
    }
    animation.play();
  });
}

function finishAnimation(animation: Animation, stopEmit: () => void) {
  stopEmit();
  emit('onCarouselEnd', 'DROP NAME');
  isAnimating.value = false;
  animation.onfinish = null;
}

function handleClickAnimate(count: number) {
  if (!props.isAuth) return;

  if (carouselElement.value) {
    isSpin.value = true;
    isAnimating.value = true;
    // проверяем нужно ли дожидаться пока появятся дополнительные рулетки
    if (count > 1) {
      animateOtherRoulets(count);
    } else {
      animateCases();
    }

    emit('onCarouselStart');
  }
}

const carouselCasesNoLine = computed(() => {
  return (idx: number) => ({
    'carousel-cases--no-line': idx !== 0,
  });
});

const carouselCasesContainerLine = computed(() => {
  return (idx: number) => ({
    'carousel-cases__cases-container_line--no-line': idx !== 0,
  });
});

defineExpose({
  start: handleClickAnimate,
});
</script>

<style src="./BKCarouselCases.scss" lang="scss" scoped></style>
