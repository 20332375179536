import type { ComposerTranslation } from 'vue-i18n';
import { Colors } from '~/constants/colors.constants';
import { FontSizes, FontWeights } from '~/types/SharedFont.types';
import type { ISharedTextProps } from '~/components/SharedText/SharedText.types';
import type { ISlideBk, TVectorTransition } from '~/features/bk/components/IFrameSlider/IFrameSlider.types';
import sliderImage1 from 'assets/images/BkIntegration/slider-image_1.png';
import sliderImage2 from 'assets/images/BkIntegration/slider-image_2.png';
import sliderImage3 from 'assets/images/BkIntegration/slider-image_3.png';
import { specialBkColor } from '~/features/bk/constants/colors';
import BkUtils from '~/features/bk/utils';

export const titleStyle = {
  color: Colors.NEUTRAL.WHITE,
  fontCase: 'upper',
  fontFamily: 'var(--secondary-font)',
  letterSpacing: '0.1em',
  weight: FontWeights.BOLD,
} as ISharedTextProps;

export const titleStyleDesktop = {
  ...titleStyle,
  size: FontSizes.LARGE_2XL,
};

export const descriptionStyle = {
  color: Colors.NEUTRAL.WHITE,
  isSmoothAnimation: true,
  size: FontSizes.MEDIUM,
  weight: FontWeights.MEDIUM,
};

export const descriptionMobileStyle = {
  ...descriptionStyle,
  size: FontSizes.SMALL,
};

// Направление исполнения анимации при переключении слайда
export const VECTOR_TRANSITION: Record<TVectorTransition, TVectorTransition> = {
  bottom: 'bottom',
  top: 'top',
} as const;

export const slidesBkInfo = (t: ComposerTranslation): ISlideBk[] => [
  {
    description: {
      text: BkUtils.Text.transformTextBk({
        spaceWords: ['Содержимое кейса', ' You can', ' Сәйкес', ' आप संबंधित'],
        str: t('firstSlideDescription'),
      }),
    },
    image: {
      png1: sliderImage1,
    },
    number: 1,
    title: {
      text: `${t('welcome')}!`,
    },
  },
  {
    description: {
      text: BkUtils.Text.transformTextBk({
        spaceWords: ['Кроме этого', ' You can', '"Құрал-жабдықтарым"', ` आप 'मेरी`],
        str: t('secondSlideDescription'),
      }),
    },
    image: {
      png1: sliderImage2,
    },
    number: 2,
    title: {
      text: t('secondSlideTitle'),
    },
  },
  {
    description: {
      text: BkUtils.Text.transformTextBk({
        spaceWords: ['После этого', 'Желаем', 'Then', 'Enjoy', ' Содан кейін', 'Ойыннан', ` एक बार`, 'अपने गेम'],
        str: t('thirdSlideDescription'),
        styledWords: [
          'трейд-ссылкой',
          'логином Steam',
          'trade URL',
          'Steam login',
          'сауда URL',
          'Steam логинін',
          'ट्रेड URL',
          'स्टीम लॉगिन',
        ],
        styledWordsLinks: [
          {
            link: 'https://steamcommunity.com/id/me/tradeoffers/privacy/#trade_offer_access_url',
            word: 'трейд-ссылкой',
          },
          { link: 'https://store.steampowered.com/account/', word: 'логином Steam' },
          {
            link: 'https://steamcommunity.com/id/me/tradeoffers/privacy/#trade_offer_access_url',
            word: 'trade URL',
          },
          { link: 'https://store.steampowered.com/account/', word: 'Steam login' },
          {
            link: 'https://steamcommunity.com/id/me/tradeoffers/privacy/#trade_offer_access_url',
            word: 'сауда URL',
          },
          { link: 'https://store.steampowered.com/account/', word: 'Steam логинін' },
          {
            link: 'https://steamcommunity.com/id/me/tradeoffers/privacy/#trade_offer_access_url',
            word: 'ट्रेड URL',
          },
          { link: 'https://store.steampowered.com/account/', word: 'स्टीम लॉगिन' },
        ],
        styles: specialBkColor,
      }),
    },
    image: {
      png1: sliderImage3,
    },
    number: 3,
    title: {
      text: t('thirdSlideTitle'),
    },
  },
];
