<template>
  <div v-if="!isError" class="popup">
    <div class="popup-content">
      <IFrameModalTop
        :is-demo="demoStore.isDemo"
        :steam-login="steamLogin"
        :steam-url="steamTradeUrl"
        :is-pending-login="isPendingLogin"
        :is-pending-link="isPendingLink"
        class="inventory-title"
        @update:steam-login="handleUpdateSteamLogin"
        @update:steam-url="handleUpdateSteamUrl"
      />

      <BKIntegrationInventoryMenu
        :disable-old-btn="disableOldBtn"
        :is-pending-new="isShowPendingNew"
        :is-pending-old="isShowPendingOld"
        :is-pending-sell-all="isPendingSellAll"
        :currency="demoStore.isDemo ? demoStore?.currency : integratorStore.urlOptions.currencyIso"
        :new-quantity="newItemsQuantity"
        :old-quantity="oldItemsQuantity"
        :sell-price="sellPrice"
        :is-show-btn-sell="isShowBtnSell"
        @on-sell-all="sellAllItems"
        @on-change-state="changeState"
      />

      <BKIntegrationOldDropList
        :background-color="localStore.eventColor"
        :item-size="ItemFormPresetPicker"
        :items="filteredItems"
        :list-loading="showLoader"
        @on-intersecting="handleIntersection"
      >
        <template #item="{ itemData, index }">
          <SharedTransitionWrapper :delay="index * 0.1" transition-name="bottom">
            <BKIntegrationItemComponent
              :item="itemData"
              :is-demo="demoStore.isDemo"
              :is-pending="isPending"
              :queue="itemIdsToReplaceInQueue"
              :custom-currency="demoStore.isDemo! ? demoStore?.currency : undefined"
              :get-button-color-preset="itemGetButtonColorPreset"
              :get-button-text-preset="itemGetButtonTextPreset"
              :price-line-color="localStore.priceLineColor"
              :sell-button-color-preset="itemSellButtonColorPreset"
              :sell-button-text-preset="itemSellButtonTextPreset"
              @on-sell="sellItem"
              @on-send="sendItem"
              @on-get="inventoryStore.getItem"
            />
          </SharedTransitionWrapper>
        </template>
        <template #empty>
          <BKIntegrationEmptyInventory
            background-color="var(--main-color-600)"
            :button-color-preset="emptyInventoryButtonColorPreset"
            @on-back="bkModalStore.handlerClose"
          />
        </template>
      </BKIntegrationOldDropList>
    </div>
  </div>
  <BKIntegrationPopupError v-else :title="t('emptyFieldTitle')" :description="t('emptyField')" />
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia';
import BKIntegrationInventoryMenu from '~/features/bk/components/BKIntegrationInventoryMenu/BKIntegrationInventoryMenu.vue';
import BKIntegrationOldDropList from '~/features/bk/components/BKIntegrationOldDropList/BKIntegrationOldDropList.vue';
import BKIntegrationItemComponent from '~/features/bk/components/BKIntegrationItemComponent/BKIntegrationItemComponent.vue';
import {
  mediumOldItemSize,
  smallNewItemSize,
  smallOldItemSize,
} from '~/features/bk/components/BKIntegrationOldDropList/BKIntegrationOldDropList.data';
import { Breakpoints } from '~/constants/media.constants';
import IFrameModalTop from '~/features/bk/components/IFrameModalTop/IFrameModalTop.vue';
import { useLocalIFrameStore } from '~/features/bk/store/localIFrame';
import {
  itemGetButtonColorPreset,
  itemGetButtonTextPreset,
  itemSellButtonColorPreset,
  itemSellButtonTextPreset,
} from '~/features/bk/components/BKIntegrationItemComponent/BKIntegrationItemComponent.data';
import { useDemoStore } from '~/features/bk/store/demo';
import BKIntegrationEmptyInventory from '~/features/bk/components/BKIntegrationEmptyInventory/BKIntegrationEmptyInventory.vue';
import { emptyInventoryButtonColorPreset } from '~/features/bk/components/BKIntegrationEmptyInventory/BKIntegrationEmptyInventory.data';
import { useBkModalStore } from '~/features/bk/store/bk.modal';
import { useInject } from '~/composables/useInject';
import type BkInventoryService from '~/features/bk/services/BkInventoryService/BkInventoryService';
import { KEY_INJECT_INVENTORY_SERVICE } from '~/features/bk/constants/index.components';
import { useBkInventoryStore } from '~/features/bk/store/bk.inventory';
import { useBkIntegratorStore } from '~/features/bk/store/bk.integrator';
import type { TInventoryBtnState } from '~/features/bk/components/BKIntegrationInventoryMenu/BKIntegrationInventoryMenu.types';
import { type IInventoryItem, UserSteamDataRequests } from '~/repository/modules/bk/bk.types';
import { INVENTORY_FETCH_DELAY, USER_UPDATE_DELAY } from '~/features/bk/constants/rules';
import type { IUpdateSteamData } from '~/features/bk/components/BKIntegrationMyInventoryPopup/BKInetgrationMyInventoryPopup.types';
import BKIntegrationPopupError from '~/features/bk/components/BKIntegrationErrorPopup/BKIntegrationPopupError.vue';
import { useBkUserStore } from '~/features/bk/store/bk.user';

const throttleFn = GlobalUtils.Functions.throttleFn;
const viewport = useViewport();
const inventoryService = useInject<BkInventoryService>(KEY_INJECT_INVENTORY_SERVICE);
const activeTab = ref<TInventoryBtnState>('new');
const isError = ref<boolean>(false);

const { t } = useI18n();

const changeState = (state: TInventoryBtnState) => {
  activeTab.value = state;
};

const ItemFormPresetPicker = computed(() => {
  if (activeTab.value === 'new') {
    return viewport.isGreaterThan(Breakpoints.md) ? mediumOldItemSize : smallNewItemSize;
  }
  if (activeTab.value === 'old') {
    return viewport.isGreaterThan(Breakpoints.sm) ? mediumOldItemSize : smallOldItemSize;
  }
  return undefined;
});

const localStore = useLocalIFrameStore();
const integratorStore = useBkIntegratorStore();
const inventoryStore = useBkInventoryStore();
const demoStore = useDemoStore();
const { totalInventoryPrice } = storeToRefs(demoStore);
const bkUser = useBkUserStore();
const { user } = storeToRefs(bkUser);
const { getNewItemsQuantity, getOldItemsQuantity } = storeToRefs(demoStore);
const {
  isPendingSellAll,
  getNewBkInventoryLength,
  getOldBkInventoryLength,
  getSellAllPrice,
  isPendingNew,
  isPendingOld,
  isPendingLink,
  isPendingLogin,
  newInventory,
  isPending,
  isTakeLengthItemsNew,
  isTakeLengthItemsOld,
  oldInventory,
  lastSuccessSaveLink,
  lastSuccessSaveLogin,
  itemIdsToReplaceInQueue,
  pendingSellItem,
} = storeToRefs(inventoryStore);
const { fetchNewInventory, fetchOldInventory } = inventoryStore;
const bkModalStore = useBkModalStore();

const isShowPendingNew = computed(() => !demoStore.isDemo && !isTakeLengthItemsNew.value);
const isShowPendingOld = computed(() => !demoStore.isDemo && !isTakeLengthItemsOld.value);
const hasSellPrice = computed(() => !!sellPrice.value);

const sellOnlyInventory = computed(() => pendingSellItem.value && getNewBkInventoryLength.value <= 1);
const sellPrice = computed(() => (demoStore.isDemo ? totalInventoryPrice.value : getSellAllPrice.value));
const isShowBtnSell = computed(() => {
  return (
    hasSellPrice.value ||
    (!isShowPendingNew.value &&
      !isShowPendingOld.value &&
      !isPendingSellAll.value &&
      !sellOnlyInventory.value &&
      !isAllItemsTaken.value &&
      getSellAllPrice.value !== 0)
  );
});
const sendItem = async (item: IInventoryItem) => {
  if (demoStore.isDemo) {
    return localStore.openPopup('demoGetItems');
  }

  // Если поля не заполнены, то показать popup с ошибкой
  if (!isValidLogin.value || !isValidTradeUrl.value) {
    isError.value = true;
    return;
  }
  isError.value = false;
  // проверка успешности постановки в очередь на вывод
  const isTake = await inventoryStore.sendItem(item);
  // Если постановка в очередь на вывод не удалась, показать popup с заменой
  // TODO проверить - возможно некорректная логика (false будет для постановки предмета в очередь?)
  if (!isTake) {
    inventoryStore.setItemToReplace(item);
    localStore.openPopup('change');
  }
};

const isValidLogin = ref<boolean>(false);
const isValidTradeUrl = ref<boolean>(false);
const steamLogin = ref<string>('');
const steamTradeUrl = ref<string>('');

const { updateSteamLogin, updateSteamLink } = inventoryStore;

onMounted(() => {
  if (!user?.value) return;
  if (user.value.steam.login || lastSuccessSaveLogin.value) {
    lastSuccessSaveLogin.value = lastSuccessSaveLogin.value || user.value.steam.login;
    steamLogin.value = lastSuccessSaveLogin.value ? lastSuccessSaveLogin.value : user.value.steam.login;
    isValidLogin.value = true;
  }

  if (user.value.steam.link || lastSuccessSaveLink.value) {
    lastSuccessSaveLink.value = lastSuccessSaveLink.value || user.value.steam.link;
    steamTradeUrl.value = lastSuccessSaveLink.value ? lastSuccessSaveLink.value : user.value.steam.link;
    isValidTradeUrl.value = true;
  }
});

const isAllItemsTaken = computed(() => {
  if (demoStore.isDemo) return false;
  return (
    getNewBkInventoryLength.value === Object.keys(itemIdsToReplaceInQueue.value)?.length ||
    !newInventory.value?.items.filter((el) => el.status === 'progress').length
  );
});

const updateUserDataThrottle = throttleFn((requestType: UserSteamDataRequests, value: string) => {
  switch (requestType) {
    case UserSteamDataRequests.UPDATE_LOGIN:
      updateSteamLogin(value);
      break;
    case UserSteamDataRequests.UPDATE_TRADE_URL:
      updateSteamLink(value);
      break;
  }
}, USER_UPDATE_DELAY);

const getInventoryThrottle = throttleFn((inventoryBtnState: TInventoryBtnState) => {
  switch (inventoryBtnState) {
    case 'new':
      fetchNewInventory();
      break;
    case 'old':
      fetchOldInventory();
      break;
  }
}, INVENTORY_FETCH_DELAY);

const handleIntersection = () => getInventoryThrottle(activeTab.value);

const handleUpdateSteamLogin: (value: IUpdateSteamData) => void = (value) => {
  steamLogin.value = value.steamLogin as string;
  isValidLogin.value = false;
  if (!!value?.isValid && steamLogin.value !== lastSuccessSaveLogin?.value) {
    isValidLogin.value = true;
    updateUserDataThrottle(UserSteamDataRequests.UPDATE_LOGIN, steamLogin.value);
  }
};

const handleUpdateSteamUrl: (value: IUpdateSteamData) => void = (value) => {
  steamTradeUrl.value = value.steamTradeUrl as string;
  isValidTradeUrl.value = false;
  if (!!value?.isValid && steamTradeUrl.value !== lastSuccessSaveLink?.value) {
    isValidTradeUrl.value = true;
    updateUserDataThrottle(UserSteamDataRequests.UPDATE_TRADE_URL, steamTradeUrl.value);
  }
};

watch([lastSuccessSaveLink, lastSuccessSaveLogin], () => {
  if (lastSuccessSaveLink.value) steamTradeUrl.value = lastSuccessSaveLink.value;
  if (lastSuccessSaveLogin.value) steamLogin.value = lastSuccessSaveLogin.value;
});

const filteredItems = computed<IInventoryItem[]>(() => {
  if (demoStore.isDemo) {
    return demoStore.inventory.filter((item) => (activeTab.value === 'new' ? !item?.isOld : item.isOld));
  }
  return activeTab.value === 'new' ? newInventory.value?.items || [] : oldInventory.value?.items || [];
});

const sellItem = (item: IInventoryItem) => {
  if (inventoryService) inventoryService.sellWonItem(item);
};

const sellAllItems = () => {
  if (inventoryService) inventoryService.sellAllItems();
};

const newItemsQuantity = computed<number>(() => {
  if (demoStore.isDemo) return getNewItemsQuantity.value;
  return getNewBkInventoryLength.value;
});

const oldItemsQuantity = computed<number>(() => {
  if (demoStore.isDemo) return getOldItemsQuantity.value;
  return getOldBkInventoryLength.value;
});

const showLoader = computed(() => {
  if (demoStore.isDemo) return false;
  if (activeTab.value === 'new' && isPendingNew.value) return true;
  if (activeTab.value === 'old' && isPendingOld.value) return true;
  return false;
});

const disableOldBtn = computed(() => {
  return demoStore.isDemo && !oldItemsQuantity.value;
});
</script>

<style lang="scss" scoped src="./BKIntegrationMyInventoryPopup.scss" />
