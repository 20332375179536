import type { TBKButtonCarouselNavigation } from './BKCarouselButtonNavigationGroup.types';
import type { ITextProps } from '~/types/SharedFont.types';
import { FontSizes, FontWeights } from '~/types/SharedFont.types';
import { Colors } from '~/constants/colors.constants';
import type { ISharedButtonNewColorPreset, ISharedButtonNewFormPreset } from '~/types/SharedButtonNew.types';
import {
  defaultButtonColorPreset,
  defaultMediumButtonTextPreset,
  defaultMiddleButtonFormPreset,
} from '~/components/SharedButtonNew/SharedButtonNew.data';

export const defaultPriceOptions: ITextProps = {
  color: Colors.MAIN.GOLD,
  weight: FontWeights.BOLD,
};

export const defaultButtons: TBKButtonCarouselNavigation = {
  backgroundStyle: 'purple',
  disableMinimalWidth: true,
  radius: '0',
  size: 'large',
};

export const defaultButtonAdded: TBKButtonCarouselNavigation = {
  backgroundStyle: 'purple',
  border: 'var(--border-radius-xs)',
  size: 'large',
  theme: 'secondary',
};

// Пресеты для loader button
// Пресеты формы
export const loaderBtnSmallFormPreset: ISharedButtonNewFormPreset = {
  ...defaultMiddleButtonFormPreset,
  height: '40px',
  minWidth: '344px',
  radius: '0 0 8px 8px',
  width: '344px',
};
export const loaderBtnMediumFormPreset: ISharedButtonNewFormPreset = {
  ...loaderBtnSmallFormPreset,
  minWidth: '440px',
  width: '440px',
};
export const loaderBtnLargeFormPreset: ISharedButtonNewFormPreset = {
  ...loaderBtnMediumFormPreset,
  height: '50px',
  minWidth: '560px',
  width: '560px',
};
// Пресеты цвета
export const loaderBtnColorPreset: ISharedButtonNewColorPreset = {
  ...defaultButtonColorPreset,
  color: 'var(--main-color-500)',
  colorHovered: 'var(--main-color-500)',
};
// Пресеты текста
export const loaderBtnSmallTextPreset: ITextProps = {
  ...defaultMediumButtonTextPreset,
  size: FontSizes.MEDIUM,
};

export const loaderBtnBigTextPreset: ITextProps = {
  ...defaultMediumButtonTextPreset,
  size: FontSizes.LARGE,
};
