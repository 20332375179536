import { defineStore } from 'pinia';
import { ref } from 'vue';
import { takeItemStateTimers } from '../components/BKIntegrationItemComponent/BKIntegrationItemComponent.data';
import { useLocalIFrameStore } from './localIFrame';
import { useBkIntegratorStore } from '~/features/bk/store/bk.integrator';
import { webSocketDatatransformTo } from '~/features/bk/store/helper.function';
import { useIntegratorApi } from '~/features/bk/composables/useIntegratorApi';
import { useBkGame } from '~/features/bk/composables/useBkGame';
import { ErrorCodes } from '~/api/global/errors/codes/codes';
import BkUtils, { DEFAULT_INVENTORY } from '~/features/bk/utils';
import type {
  IBkInventory,
  IBkInventoryItem,
  IBkUpdateUserData,
  IDemoItem,
  IInventoryItem,
  IReplaceItemResponse,
  ISellBkItem,
  ISendingCurrencyExchange,
  IWSStateChangeEvent,
  TBkApi,
  TBkInventory,
  TPagesInventory,
} from '~/repository/modules/bk/bk.types';
import { EInventoryTakeStates } from '~/repository/modules/bk/bk.types';
import type { TCurrencyType } from '~/types/Shared.types';
import { useAlertStore } from '~/store/alert/alert.store';
import type { IError } from '~/repository/extensions/error/error.types';
import { AlertCodes } from '~/store/alert/alert.messages';
import { GlobalUtils } from '~/utils';
import type { IBkCaseDropItem } from '~/repository/modules/bk/bkCases.types';
import { WEBSOCKET_FIRST_CHANNEL } from '~/constants/app.constants';
import type { IWebsocketResponse } from '~/api/global/webosket/websocket.types';
import { useBkUserStore } from '~/features/bk/store/bk.user';
import type { IResponseSendItem } from '~/features/bk/types/inventoryItems.types';
import DataMapper from '~/api/global/DataMapper';
import { getAlertStatus } from '~/utils/cases/status.utils';
import { errorKeyCodes } from '~/features/bk/constants/codes.error';

import type { TStatuses } from '~/types/cases/status';
import { keyWsStatus, statuses } from '~/types/cases/status';
import { dropItemTypes } from '~/types/cases/dropItem';
import { useBkTakeItems } from '~/features/bk/composables/useBkTakeItems';
import { isTastyDrop } from '~/api/global/samples/BkApiSample';
import type { TCurrentBk } from '~/features/bk/types/inventory.types';

function getStatusErrorCode(msg: string): number {
  if (typeof msg === 'string') {
    const str = msg.split(':');
    if (str[1]) return parseFloat(str[1]);
  }
  return 5005;
}

function getBkHttp(item: IInventoryItem): 'bk' | 'bkSecond' {
  /** Является ли предмет кс **/
  return item.weapon ? 'bkSecond' : 'bk';
}

// В зависимости от игры и принадлежности предмета инвентаря к игре определяем куда будем отправлять запрос
const getRequestUrl = (item: IInventoryItem, isDotaGame: boolean, isCsGame: boolean): 'bk' | 'bkSecond' => {
  const isDotaItem = getBkHttp(item) === 'bk';
  const isCsItem = getBkHttp(item) === 'bkSecond';

  return (isDotaGame && isDotaItem) || (isCsGame && isCsItem) ? 'bk' : 'bkSecond';
};

// Метод позволяет заменить элемент массива по совпадающему id
function replaceObjectInArray(array: Ref<IBkInventory<IInventoryItem>>, id: number, newObject: IInventoryItem) {
  const index = array.value.items.findIndex((item) => +item.id === id);
  if (index !== -1) {
    array.value.items[index] = newObject;
  }
}

function getErrorMultiLanguageKey(key: keyof typeof errorKeyCodes): string {
  return errorKeyCodes[key];
}

function transformTo(
  data: IBkInventory<IBkInventoryItem>,
  isOld: boolean,
  currency: TCurrencyType,
  tradeOfferMap: Ref<Map<number, number>>,
): IBkInventory<IInventoryItem> {
  const items: IDemoItem[] = [];

  data.items.forEach((item) => {
    if (item.offerId) tradeOfferMap.value.set(+item.id, item.offerId);
    const obj = {
      ...item,
      datetime: item.datetime || '',
      id: item.id,
      imageData: {
        image: item.image,
      },
      inactive: false,
      isOld,
      isUltraRare: item.isUltraRare,
      name: item.name,
      offerData: {
        countdown: item.countdown,
        currency,
        price: item.price,
      },
      offerId: item.offerId,
      priceData: {
        currency,
        marketPrice: String(item.marketPrice),
        price: item.price,
      },
      qualityEnum: {
        name: item.quality,
      },
      status: item.status || 'active',
      taken: true,
      tastyCoins: null,
      timeUntilSending: item.timeUntilSending || 0,
      type: item.type,
    };
    items.push(obj);
  });

  return { ...data, items } as IBkInventory<IInventoryItem>;
}

export const useBkInventoryStore = defineStore('bk/inventoryStore', () => {
  const {
    $i18n: { t },
  } = useNuxtApp();

  const alertStore = useAlertStore();
  const localIframeStore = useLocalIFrameStore();
  const { isCarouselAnimation } = storeToRefs(localIframeStore);

  const nuxtApp = useNuxtApp();
  const mapper = new DataMapper();

  const itemForAddToInventory = ref<IInventoryItem | null>(null);
  const isPending = ref<boolean>(false);
  const isPendingLogin = ref<boolean>(false);
  const isPendingLink = ref<boolean>(false);
  const localStore = useLocalIFrameStore();

  const pagesOldInventory = ref<TPagesInventory>(BkUtils.Inventory.setInventoryPagesData());
  const pagesNewInventory = ref<TPagesInventory>(BkUtils.Inventory.setInventoryPagesData());
  const newInventory = ref<IBkInventory<IInventoryItem>>(DEFAULT_INVENTORY);
  const oldInventory = ref<IBkInventory<IInventoryItem>>(DEFAULT_INVENTORY);

  // Статусы загрузки инвентаря
  const isPendingNew = ref<boolean>(true);
  const isPendingOld = ref<boolean>(false);
  const isTakeLengthItemsOld = ref<boolean>(false);
  const isTakeLengthItemsNew = ref<boolean>(false);
  const isPendingSellAll = ref<boolean>(false);
  const pendingSellItem = ref<boolean | string>(false);
  const addedOld = ref(0);
  const addedNew = ref(0);
  const currentCurrency = ref<TCurrencyType>('USD');
  const exchangeRate = ref<number>(0);

  const { popups } = storeToRefs(localStore);

  // Элемент на замену
  const itemToReplacement = ref<IInventoryItem | null>();
  // элементы на замену, чтобы нельзя было нажать забрать несколько раз
  const itemIdsToReplaceInQueue = ref<Record<number, number>>({});
  // Элементы для замены
  const itemsForReplacement = ref<IBkCaseDropItem[]>([]);
  // Элементы выбранные на замену
  const itemsSelectedForReplacement = ref<IBkCaseDropItem[]>([]);

  const { $api } = useNuxtApp();
  const integratorStore = useBkIntegratorStore();
  const userBK = useBkUserStore();
  const { user } = storeToRefs(userBK);
  const { urlOptions } = storeToRefs(integratorStore);
  const { createAuthUrl, defaultUrl } = useIntegratorApi(urlOptions);
  const lastSuccessSaveLogin = ref<string>('');
  const lastSuccessSaveLink = ref<string>('');
  const tradeOfferMap = ref<Map<number, number>>(new Map([]));
  // Предметы/предмет выбранные пользователем по равной стоимости на замену
  const tradeItems = ref<IBkCaseDropItem[]>([]);

  const STATUSES_TO_ADD_TIMER_DATA: TStatuses[] = [statuses.send, statuses.completed]; // список статусов для добавления таймера

  // getters
  const getNewBkInventoryLength = computed<number>(() => {
    return (newInventory.value?.countItems || 0) + addedNew.value;
  });

  const getOldBkInventoryLength = computed<number>(() => {
    return oldInventory.value?.countItems ? oldInventory.value.countItems + addedOld.value : 0;
  });

  const getSellAllPrice = computed<number>(() => {
    if (!newInventory.value || !newInventory.value.items) return 0;

    // Фильтруем предметы со статусом "progress" и вычисляем их общую цену что бы вычесть проданные предметы из общей суммы
    const totalPrice = newInventory.value.items
      .filter((item) => item.status === 'progress' && !item.takeItemState)
      .reduce((total, item) => total + parseFloat(item.priceData.price), 0);

    return parseFloat(totalPrice.toFixed(2));
  });

  /** Композабл для работы со статами вывода предмета **/
  const { setTakeItemState, changeItemState } = useBkTakeItems(newInventory);

  // добавляем предмет в новые, в начало
  const addItemToNewInventory = (data: IDemoItem) => {
    const obj = Object.assign(data, {
      status: 'progress' as TStatuses,
      timeUntilSending: 0,
    }) as IInventoryItem;

    if (!newInventory.value) return;
    if (newInventory.value?.totalAmount !== 0 && !newInventory.value.totalAmount)
      newInventory.value.totalAmount = +data.priceData.price;
    else {
      newInventory.value.totalAmount = +data.priceData.price + +newInventory.value.totalAmount;
    }
    if (!isCarouselAnimation.value) {
      // проверяем, чтобы не в момент прокрута рулетки
      addedNew.value++;
      newInventory.value?.items?.unshift(obj);
      return;
    }
    itemForAddToInventory.value = obj;
  };
  watch(isCarouselAnimation, (newVal) => {
    if (newVal || !itemForAddToInventory.value) return;
    addedNew.value++;
    newInventory.value?.items?.unshift(itemForAddToInventory.value);
    itemForAddToInventory.value = null;
  });

  // Продажа элементов из инвентаря
  const sellAllItems = async () => {
    if (isPendingSellAll.value) return;
    await GlobalUtils.Api.handleRequest(
      async () => {
        // Определяем, есть ли предметы для Dota и CS
        const hasDotaItems = newInventory.value?.items.some((item) => getBkHttp(item) === 'bk');
        const hasCsItems = newInventory.value?.items.some((item) => getBkHttp(item) === 'bkSecond');

        /*
         *  Для дота предметов, если это проект tastydrop, идем на $api.bk, иначе $api.bkSecond
         *  Для кс предметов наоборот, если это проект tastydrop идем на $api.bkSecond, иначе $api.bk
         **/

        const sellItemsHelper = async (apiFirst: TCurrentBk, apiSecond: TCurrentBk, condition: boolean) => {
          const api = condition ? apiFirst : apiSecond;
          await api.sellAllItemInventory<ISellBkItem>({ createAuthUrl });
        };

        if (hasDotaItems) await sellItemsHelper($api.bk, $api.bkSecond, isTastyDrop());
        if (hasCsItems) await sellItemsHelper($api.bkSecond, $api.bk, isTastyDrop());

        if (!newInventory.value || !oldInventory.value) return;
        oldInventory.value.items = [
          ...(newInventory.value?.items ?? []).map((item) => {
            return { ...item, isOld: true, status: statuses.selled };
          }),
          ...(oldInventory.value?.items ?? []),
        ];
        newInventory.value.items = [];
        addedOld.value += addedNew.value;
        oldInventory.value.countItems += newInventory.value.countItems;
        newInventory.value.countItems = 0;
        addedNew.value = 0;
        alertStore.show({
          title: AlertCodes.ALL_ITEMS_IN_SALE_QUEUE,
          type: 'success',
        });
      },
      (e: IError) => informOnError(e),
      isPendingSellAll,
    );
  };

  const sellWonItem = async (wonItem: IInventoryItem) => {
    if (isPending.value) return;
    const { isDotaGame, isCsGame } = useBkGame();

    await GlobalUtils.Api.handleRequest(
      async () => {
        const id = String(wonItem.id);
        pendingSellItem.value = id;
        const bkHttp = getRequestUrl(wonItem, isDotaGame, isCsGame);

        await $api[bkHttp].sellItemInventory<ISellBkItem>({ createAuthUrl, id });
        pendingSellItem.value = false;
        const selledSubject = newInventory.value?.items?.find((item) => item.id === id);
        if (selledSubject) {
          selledSubject.isOld = true;
          selledSubject.status = statuses.selled;
          oldInventory.value && oldInventory.value.items.unshift(selledSubject);
        }
        if (newInventory.value) newInventory.value.items = newInventory.value?.items.filter((item) => item.id !== id);
        addedOld.value++;
        addedNew.value--;
        alertStore.show({
          title: AlertCodes.ITEM_IN_SALE_QUEUE,
          type: 'success',
        });
      },
      (e: IError) => informOnError(e),
      isPending,
    );
  };

  const updateSteamLogin = async (value: string) => {
    if (isPendingLogin.value) return;
    await GlobalUtils.Api.handleRequest(
      async () => {
        const data = await Promise.all([
          nuxtApp.$api.bk.setSteamLogin<IBkUpdateUserData>(value, urlOptions.value['auth-token'], {
            bkPrefix: defaultUrl.value,
          }),
          nuxtApp.$api.bkSecond.setSteamLogin<IBkUpdateUserData>(value, urlOptions.value['auth-token'], {
            bkPrefix: defaultUrl.value,
          }),
        ]);

        if (!data.some((responseItem) => responseItem.success)) return;

        lastSuccessSaveLogin.value = value;
        alertStore.show({
          title: AlertCodes.DATA_UPDATED,
          type: 'success',
        });
      },
      (e: IError) => informOnError(e),
      isPendingLogin,
    );
  };
  const updateSteamLink = async (value: string) => {
    if (isPendingLink.value) return;
    await GlobalUtils.Api.handleRequest(
      async () => {
        const data = await Promise.all([
          nuxtApp.$api.bk.setSteamTradeLink<IBkUpdateUserData>(value, urlOptions.value['auth-token'], {
            bkPrefix: defaultUrl.value,
          }),
          nuxtApp.$api.bkSecond.setSteamTradeLink<IBkUpdateUserData>(value, urlOptions.value['auth-token'], {
            bkPrefix: defaultUrl.value,
          }),
        ]);

        if (!data.some((responseItem) => responseItem.success)) return;

        lastSuccessSaveLink.value = value;
        alertStore.show({
          title: AlertCodes.DATA_UPDATED,
          type: 'success',
        });
      },
      (e: IError) => informOnError(e),
      isPendingLink,
    );
  };

  const replaceItem = async () => {
    return await GlobalUtils.Api.handleRequest(
      async () => {
        if (!itemToReplacement.value) return;
        if (!tradeItems.value.length) return;
        if (!newInventory.value?.items) return;

        const itemId = +itemToReplacement.value?.id;
        const replaceId = +tradeItems.value[0].id;

        const bkHttp = getBkHttp(itemToReplacement.value);
        const { itemIdInInventory, status } = await $api[bkHttp].replaceItem<IReplaceItemResponse>({
          createAuthUrl,
          itemId,
          replaceId,
        });

        function transformItem(item: IBkCaseDropItem): IInventoryItem {
          return {
            ...item,
            id: +itemIdInInventory!,
            isUltraRare: false,
            offerData: {
              countdown: item.offerData?.countdown || 0,
              price: String(item.offerData?.price),
            },
            priceData: {
              currency: item.priceData.currency,
              marketPrice: String(item.priceData?.marketPrice),
              price: String(item.priceData.price),
            },
            status: 'progress',
            taken: true,
          };
        }

        if (status) {
          const itemReplace = tradeItems.value.find((el) => +el.id === replaceId);
          replaceObjectInArray(newInventory as Ref<IBkInventory<IInventoryItem>>, itemId, transformItem(itemReplace!));
          alertStore.show({
            title: t('successReplaceItem'),
            type: 'success',
          });
          return true;
        }
      },
      (e: IError) => informOnError(e),
      isPending,
    );
  };

  // запрос юзера чтобы забрать предмет
  const sendItem = async (item: IInventoryItem) => {
    const { isDotaGame, isCsGame } = useBkGame();
    const id = +item.id;
    const method = item.type === '3' ? 'withdrawal' : 'senditem';

    // ставит в очередь на бэке на отправку в steam. возвращает булево
    return await GlobalUtils.Api.handleRequest(
      async () => {
        if (itemIdsToReplaceInQueue.value?.[id]) return true;

        itemIdsToReplaceInQueue.value[id] = id;
        const bkHttp = getRequestUrl(item, isDotaGame, isCsGame);

        const data = await $api[bkHttp].sendItem<ISendingCurrencyExchange>({
          createAuthUrl,
          id,
          method,
        });

        // проверка успешно ли предметы поставлены в очередь на вывод и ожидаем ли продавца
        if (data?.status) {
          currentCurrency.value = data.currency;
          exchangeRate.value = Object.values(data.exchangeRate)[0];

          const takeItemStateSet =
            item.type && +item.type === dropItemTypes['3']
              ? EInventoryTakeStates.WE_CHARGE_TO_THE_BALANCE
              : EInventoryTakeStates.PREPARING_ITEM;
          setTakeItemState({
            alertText: AlertCodes.PREPARING_TO_DISPATCH,
            id,
            takeItemStateSet,
            time: takeItemStateTimers.prepareItemTimer,
          });
          return true;
        }
        delete itemIdsToReplaceInQueue.value[id];
        return false;
      },
      (e: IError) => {
        informOnError(e);
        delete itemIdsToReplaceInQueue.value?.[id];
      },
    );
  };

  const setItemToReplace = (item: IInventoryItem) => {
    itemToReplacement.value = item;
  };

  const resetItemToReplace = () => {
    itemToReplacement.value = null;
  };

  const updatePage = (inventoryType: TBkInventory, apiType: TBkApi, value: number) => {
    switch (inventoryType) {
      case 'new':
        pagesNewInventory.value[apiType].current++;
        if (value) pagesNewInventory.value[apiType].total = value;
        break;
      case 'old':
        pagesOldInventory.value[apiType].current++;
        if (value) pagesNewInventory.value[apiType].total = value;
    }
  };

  const lastPageNewInventory = computed<boolean>(() => {
    return (
      pagesNewInventory.value.base.current + pagesNewInventory.value.second.current >=
      pagesNewInventory.value.base.total + pagesNewInventory.value.second.total
    );
  });

  const lastPageOldInventory = computed<boolean>(() => {
    return (
      pagesOldInventory.value.base.current + pagesOldInventory.value.second.current >=
      pagesOldInventory.value.base.total + pagesOldInventory.value.second.total
    );
  });

  const fetchNewInventory = async () => {
    if (isPendingNew.value || lastPageNewInventory) return;
    await getNewInventory();
  };
  const fetchOldInventory = async () => {
    if (isPendingOld.value || lastPageOldInventory) return;
    await getOldInventory();
  };

  const getNewInventory = async () => {
    await GlobalUtils.Api.handleRequest(
      async () => {
        // получение айтемов инвентаря с основго БКАпи
        const dataBaseBk = await $api.bk.getNewInventory<IBkInventory<IBkInventoryItem>>(
          { createAuthUrl },
          pagesNewInventory.value.base.current + 1,
        );
        // обновление данных для запроса по пагинации c основного БК апи
        updatePage('new', 'base', dataBaseBk.countPages);
        // получение айтемов инвентаря с дополнительного БКАпи
        const dataSecondBk = await $api.bkSecond.getNewInventory<IBkInventory<IBkInventoryItem>>(
          { createAuthUrl },
          pagesNewInventory.value.second.current + 1,
        );
        // обновление данных для запроса по пагинации c доп БК апи
        updatePage('new', 'second', dataBaseBk.countPages);
        // формирование массива состоящего из элементов с основного и доп БК АПИ и перемешанного
        const itemsSorted = [
          ...transformTo(dataBaseBk, false, urlOptions.value.currencyIso as TCurrencyType, tradeOfferMap).items,
          ...transformTo(dataSecondBk, false, urlOptions.value.currencyIso as TCurrencyType, tradeOfferMap).items,
        ].sort((a, b) => +new Date(b.datetime!) - +new Date(a.datetime!));

        // Проверяем каждый элемент на наличие параметра timeUntilSending со значением 0
        itemsSorted.forEach((item) => {
          if (!item.status || !STATUSES_TO_ADD_TIMER_DATA.includes(item.status)) return; // Если статус не соответствует ни одному из статусов для добавления таймера, пропускаем
          item.timeUntilSending = item.timeUntilSending || takeItemStateTimers.prepareItemTimer; // Заменяем значение
          // на 300, если равно 0
          const { timerData, takeItemState } = changeItemState({
            takeItemStateSet: EInventoryTakeStates.PREPARING_ITEM,
            time: item.timeUntilSending,
          });
          if (timerData) item.timerData = timerData;
          if (takeItemState) item.takeItemState = takeItemState;
        });

        if (!newInventory.value?.items?.length) {
          newInventory.value = {
            countItems: dataBaseBk.countItems + dataSecondBk.countItems,
            countPages: dataBaseBk.countPages + dataSecondBk.countPages,
            // айтемы инвентаря устанавливаются в один массив который перемешивается
            items: [...itemsSorted],
            totalAmount: Number(dataBaseBk.totalAmount) + Number(dataSecondBk.totalAmount),
            user: dataBaseBk.user,
          };
        } else {
          newInventory.value.items.push(...itemsSorted);
        }
        // Выполняем один раз
        if (!isTakeLengthItemsNew.value) isTakeLengthItemsNew.value = true;
      },
      () => {
        informOnError({
          key: ErrorCodes.INVENTORY_ERROR,
          msg: '',
        });
      },
      isPendingNew,
    );
  };

  const getOldInventory = async () => {
    await GlobalUtils.Api.handleRequest(
      async () => {
        // получение айтемов инвентаря с основго БКАпи
        const dataBaseBk = await $api.bk.getOldInventory<IBkInventory<IBkInventoryItem>>(
          { createAuthUrl },
          pagesOldInventory.value.base.current + 1,
        );
        // обновление данных для запроса по пагинации c основного БК апи
        updatePage('old', 'base', dataBaseBk.countPages);
        // получение айтемов инвентаря с дополнительного БКАпи
        const dataSecondBk = await $api.bkSecond.getOldInventory<IBkInventory<IBkInventoryItem>>(
          { createAuthUrl },
          pagesOldInventory.value.second.current + 1,
        );
        // обновление данных для запроса по пагинации c доп БК апи
        updatePage('old', 'second', dataBaseBk.countPages);
        // формирование массива состоящего из элементов с основного и доп БК АПИ и перемешанного
        const itemsFetched = [
          ...transformTo(dataBaseBk, true, urlOptions.value.currencyIso as TCurrencyType, tradeOfferMap).items,
          ...transformTo(dataSecondBk, true, urlOptions.value.currencyIso as TCurrencyType, tradeOfferMap).items,
        ].sort((a, b) => +new Date(b.datetime!) - +new Date(a.datetime!));
        if (!oldInventory.value?.items?.length) {
          oldInventory.value = {
            countItems: dataBaseBk.countItems + dataSecondBk.countItems,
            countPages: dataBaseBk.countPages + dataSecondBk.countPages,
            // айтемы инвентаря устанавливаются в один массив который перемешивается
            items: [...itemsFetched],
            totalAmount: Number(dataBaseBk.totalAmount) + Number(dataSecondBk.totalAmount),
            user: dataBaseBk.user,
          };
        } else {
          oldInventory.value.items.push(...itemsFetched);
        }
        // Выполняем один раз
        if (!isTakeLengthItemsOld.value) isTakeLengthItemsOld.value = true;
      },
      (e: IError) => informOnError(e),
      isPendingOld,
    );
  };

  function changeStatusItem(inventoryId: number, status: TStatuses) {
    if (!newInventory.value?.items.length) return;
    newInventory.value.items = newInventory.value?.items.map((item) => {
      if (+item.id === +inventoryId) {
        const alert = getAlertStatus(status);
        if (alert) {
          alertStore.show(alert);
        }

        return {
          ...item,
          // примерные данные
          status,
        };
      }
      return item;
    }) as IInventoryItem[];
  }

  // проверка - возможно ли вывести предмет или нет (предлагаются ли при выводе предметы на замену)
  const connectWsItemResponse = () => {
    return $api.websocket.subscribe(WEBSOCKET_FIRST_CHANNEL, {
      cb: (data: IWebsocketResponse) => {
        const parse = JSON.parse(data.msg);
        const mapData = mapper.mapDataKeys(parse) as IResponseSendItem;
        // Определяем что ивент приходящий в сокете относится к текущему юзеру
        if (!user.value) return;
        if (+mapData.user !== +user.value.userId) return;
        // Если ивент не является ошибкой/обработкой ошибки/успешного статуса по выводу, например замена на вывод, то меняем статус на отправлен.
        if (
          (!mapData.response.msg && !mapData.response.replacements?.items?.length) ||
          Object.hasOwn(mapData.response, '')
        ) {
          changeStatusItem(+mapData.inventoryId, 'send');
          setTakeItemState({
            id: +mapData.inventoryId,
            takeItemStateSet: EInventoryTakeStates.AWAITING_SELLER,
            time: takeItemStateTimers.awaitSellerTimer,
          });
          return;
        }
        // Обработка ошибки по статусу, с использованием мультиязчности
        if (!mapData.response.status) {
          if (mapData.response.type === statuses.no_link) {
            if (!mapData.inventoryId) return;
            setTakeItemState({
              id: +mapData.inventoryId,
              takeItemStateSet: null,
              time: null,
            });
            changeStatusItem(+mapData.inventoryId!, keyWsStatus.canceled);
            delete itemIdsToReplaceInQueue.value[+mapData.inventoryId];
            alertStore.showError({
              title: t('checkInventoryOpenForTake'),
            });
          }
          const msg = mapData.response.msg;
          const errorCode = getStatusErrorCode(msg);
          if (!errorCode) return;
          const key = getErrorMultiLanguageKey(errorCode);
          if (!key) throw new Error(`Ключ не найден: ${key}, ${msg}`);
          alertStore.showError({ title: t(key) });

          if (!mapData.inventoryId) return;
          itemIdsToReplaceInQueue.value[+mapData.inventoryId] &&
            delete itemIdsToReplaceInQueue.value[+mapData.inventoryId];
        }
        // Если пришли предметы на замены, то добавляем их и выводим в попап
        if (mapData.response && mapData.response.replacements?.items?.length && !popups.value.change) {
          setTakeItemState({ id: +mapData.response.inventoryId, takeItemStateSet: null, time: null });
          tradeItems.value = [];
          const transformArr = webSocketDatatransformTo(mapData.response.replacements.items, urlOptions.value);
          const itemTo = newInventory.value?.items.find((item) => +item.id === +mapData.response.inventoryId);
          if (!itemTo) return;
          itemToReplacement.value = {
            ...itemTo,
            offerData: {
              countdown: mapData.response.replacements.offerEndTime,
              currency: currentCurrency.value,
              price: String(
                currencyUtils.Currency.convertToCurrency(mapData.response.replacements.bonusPrice, exchangeRate.value),
              ),
            },
          };
          itemsForReplacement.value = [...transformArr];
          localStore.openPopup('change');
          itemIdsToReplaceInQueue.value[+itemTo.id] && delete itemIdsToReplaceInQueue.value[+itemTo.id];
        }
      },
      event: 'senditem_response',
      uniqueKey: 'BkInventory/ItemsToReplacement',
    }) as () => boolean;
  };
  // не связано с заменой - изменение статуса предмета
  const connectWsStateChange = () => {
    return $api.websocket.subscribe(WEBSOCKET_FIRST_CHANNEL, {
      cb: (data: IWebsocketResponse) => {
        const parse = JSON.parse(data.msg);
        const mapData = mapper.mapDataKeys(parse) as IWSStateChangeEvent;
        if (!user.value) return;
        // Определяем относится ли событие к текущему юзеру
        if (+user.value.userId === +mapData.user) {
          if (mapData.offer) tradeOfferMap.value.set(+mapData.item, +mapData.offer);
          changeStatusItem(+mapData.item, keyWsStatus[mapData.type]);
          if (mapData.type === 'canceled') {
            delete itemIdsToReplaceInQueue.value[+mapData.item];
            setTakeItemState({
              id: +mapData.item,
              takeItemStateSet: null,
              time: null,
            });
            alertStore.showError({ title: t('alerts.itemSendCancelled') });
          }

          if (mapData.type === 'send') {
            // обновление статуса получения предмета пользователем
            // 1. поправка для того чтобы вычесть истекшее время с постановки таймера Ждем продавца
            const item = newInventory.value?.items.find((item) => +item.id === +mapData.item);
            if (item && item.timerData?.timerSetTimeStamp) {
              const timeAmendment = item.timerData?.timerSetTimeStamp
                ? Date.now() - item.timerData?.timerSetTimeStamp
                : 0;
              // 2. непоср установка статуса с таймером
              setTakeItemState({
                id: +mapData.item,
                takeItemStateSet: EInventoryTakeStates.AWAITING_TAKING,
                time: takeItemStateTimers.takeItemTimer - timeAmendment,
              });
            }
          }
          // TODO ? проверить
          if (mapData.type === 'accept') {
            // TODO оптимизировать
            const item = newInventory.value?.items.find((item) => +item.id === +mapData.item);
            if (!item) return;
            setTakeItemState({
              id: +mapData.item,
              takeItemStateSet: null,
              time: null,
            });
            // Переводим предмет из новых в старые, после успешного вывода
            if (newInventory.value) {
              newInventory.value = {
                ...newInventory.value,
                items: newInventory.value?.items.filter((item) => +item.id !== +mapData.item) ?? [],
              };
              newInventory.value.countItems--;
            }
            oldInventory.value?.items.unshift({ ...item, isOld: true });
            oldInventory.value && oldInventory.value.countItems++;
          }
        }
      },
      event: 'statechange',
      uniqueKey: 'BkInventory/ItemsToReplacement/StateChange',
    }) as () => boolean;
  };

  const getItem = (id: number) => {
    if (!tradeOfferMap.value.has(id)) return;
    window.open(`https://steamcommunity.com/tradeoffer/${tradeOfferMap.value.get(id)}/}`, '_blank');
  };

  return {
    addItemToNewInventory,
    connectWsItemResponse,
    connectWsStateChange,
    currentCurrency,
    exchangeRate,
    fetchNewInventory,
    fetchOldInventory,
    getItem,
    getNewBkInventoryLength,
    getNewInventory,
    getOldBkInventoryLength,
    getOldInventory,
    getSellAllPrice,
    isPending,
    isPendingLink,
    isPendingLogin,
    isPendingNew,
    isPendingOld,
    isPendingSellAll,
    isTakeLengthItemsNew,
    isTakeLengthItemsOld,
    itemIdsToReplaceInQueue,
    itemToReplacement,
    itemsForReplacement,
    itemsSelectedForReplacement,
    lastSuccessSaveLink,
    lastSuccessSaveLogin,
    newInventory,
    oldInventory,
    pendingSellItem,
    replaceItem,
    resetItemToReplace,
    sellAllItems,
    sellWonItem,
    sendItem,
    setItemToReplace,
    setTakeItemState,
    tradeItems,
    updateSteamLink,
    updateSteamLogin,
  };
});
