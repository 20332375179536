<template>
  <div class="action-buttons">
    <SharedButtonNew
      v-for="(actionButton, index) in buttons"
      :key="index"
      :form="actionButtonFromPreset"
      :color="actionButtonColorPreset"
      :text-props="textProperties"
      :style="btnRadius"
      @click="actionButton.action"
    >
      <template #prepend>
        <component :is="actionButton.icon" class="action-button-icon" />
      </template>
      {{ actionButton.text }}
    </SharedButtonNew>
  </div>
</template>
<script setup lang="ts">
import {
  actionButtonBigTextPreset,
  actionButtonColorPreset,
  actionButtonFromPreset,
  actionButtonSmallTextPreset,
} from '~/features/bk/components/BKIntegrationActionButtons/BKIntegrationActionButtons.data';
import { Breakpoints } from '~/constants/media.constants';
import type { IBkIntegrationActionButtonsProps } from '~/features/bk/components/BKIntegrationActionButtons/BkIntegrationActionButtons.types';

defineProps<IBkIntegrationActionButtonsProps>();

const viewport = useViewport();

const textProperties = computed(() => {
  return viewport.isLessThan(Breakpoints.xl) ? actionButtonSmallTextPreset : actionButtonBigTextPreset;
});

const btnRadius = computed(() => ({
  'border-radius': viewport.isGreaterOrEquals(Breakpoints.md) ? 'var(--border-radius-xs)' : 'var(--border-radius-3xs)',
}));
</script>
<style scoped lang="scss" src="./BKIntegrationActionButtons.scss"></style>
