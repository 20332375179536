export const statuses = {
  // Сделка на ТП(торговой площадке) состоялась
  accepted: 'accepted',

  // Отмена получения предмета
  canceled: 'canceled',

  // отправлено пользователю
  completed: 'completed', // Получить

  // ошибка
  error: 'error',

  // некорректная ссылка
  no_link: 'no_link',

  // Без каких-либо действий
  progress: 'progress',

  // Предмет продан
  selled: 'selled',

  // Выполняется поиск предмета, предмет отправлен в очередь
  send: 'send', // Ждем продавца

  // Автопродажа
  autoselled: 'autoselled',
} as const;

export const keyWsStatus = {
  accept: 'accepted',
  canceled: 'progress',
  no_link: 'no_link',
  send: 'completed',
} as const;

export type TStatuses = keyof typeof statuses;
export type TTypeStatuses = keyof typeof keyWsStatus;
