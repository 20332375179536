import { computed, ref } from 'vue';
import type { TComponentName } from '~/features/bk/types/components.types';
import { ComponentNames, listComponent } from '~/features/bk/constants/index.components';

export const useBkModalStore = defineStore('bk/modal', () => {
  const isModalOpened = ref<boolean>(false);
  const currentComponent = ref<TComponentName>(ComponentNames.CASE_CONTENT);

  const modelModalOpened = computed({
    get() {
      return isModalOpened.value;
    },
    set(val: boolean) {
      isModalOpened.value = val;
    },
  });

  const modalComponent = computed(() => {
    if (currentComponent.value) {
      return listComponent[currentComponent.value];
    }
    return '';
  });

  const setCurrentComponent = (componentName: TComponentName) => {
    currentComponent.value = componentName;
    isModalOpened.value = true;
  };

  const handlerClose = (componentName?: TComponentName) => {
    isModalOpened.value = false;
    if (componentName) setCurrentComponent(componentName);
  };

  return {
    handlerClose,
    isModalOpened,
    modalComponent,
    modelModalOpened,
    setCurrentComponent,
  };
});
