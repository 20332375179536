import {
  defaultButtonColorPreset,
  defaultMediumButtonTextPreset,
  defaultMiddleButtonFormPreset,
} from '~/components/SharedButtonNew/SharedButtonNew.data';
import type {
  ISharedButtonNewColorPreset,
  ISharedButtonNewFormPreset,
  TSharedButtonTextPreset,
} from '~/types/SharedButtonNew.types';

export const emptyInventorySmallButtonFormPreset: ISharedButtonNewFormPreset = {
  ...defaultMiddleButtonFormPreset,
  height: '40px',
  minWidth: '200px',
  radius: '80px',
  width: '200px',
};

export const emptyInventoryMediumButtonFormPreset: ISharedButtonNewFormPreset = {
  ...emptyInventorySmallButtonFormPreset,
  height: '50px',
  minWidth: '256px',
  width: '256px',
};

export const emptyInventoryButtonColorPreset: ISharedButtonNewColorPreset = {
  ...defaultButtonColorPreset,
  color: 'var(--bk-integration-purple)',
  colorHovered: 'var(--bk-integration-purple)',
};

export const emptyInventorySmallButtonTextPreset: TSharedButtonTextPreset = {
  ...defaultMediumButtonTextPreset,
  color: 'var(--neutral-white)',
  size: 'var(--font-size-m)',
};

export const emptyInventoryMediumButtonTextPreset: TSharedButtonTextPreset = {
  ...defaultMediumButtonTextPreset,
  color: 'var(--neutral-white)',
  size: 'var(--font-size-l)',
};
