import {
  defaultButtonColorPreset,
  defaultMediumButtonTextPreset,
  defaultMiddleButtonFormPreset,
} from '~/components/SharedButtonNew/SharedButtonNew.data';
import type {
  ISharedButtonNewColorPreset,
  ISharedButtonNewFormPreset,
  TSharedButtonTextPreset,
} from '~/types/SharedButtonNew.types';

export const actionButtonFromPreset: ISharedButtonNewFormPreset = {
  ...defaultMiddleButtonFormPreset,
  flexDir: 'column',
  height: '76px',
  justifyContent: 'flex-start',
  media: {
    xl: { height: '96px', minWidth: '112px', width: '112px' },
    xxl: { height: '96px', minWidth: '112px', width: '112px' },
  },
  minWidth: '80px',
  width: '80px',
};

export const actionButtonColorPreset: ISharedButtonNewColorPreset = {
  ...defaultButtonColorPreset,
  color: 'var(--main-color-200)',
  colorBorder: 'var(--main-color-200)',
  colorHovered: 'var(--main-color-100)',
};

export const actionButtonBigTextPreset: TSharedButtonTextPreset = {
  ...defaultMediumButtonTextPreset,
  size: 'var(--font-size-m)',
};

export const actionButtonSmallTextPreset: TSharedButtonTextPreset = {
  ...defaultMediumButtonTextPreset,
  size: 'var(--font-size-xs)',
};
