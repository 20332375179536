<template>
  <div class="menu-container">
    <div class="menu-buttons__left">
      <SharedButtonNew
        :form="inventoryButtonFormPresetPicker"
        :color="inventoryBtnColorsPreset"
        :state="{ active: state === 'new' }"
        :text-props="inventoryItemButtnTextPresetPicker"
        @click="changeState('new')"
      >
        {{ t('inventoryNew') }}
        <span v-if="!isPendingNew" class="button-text__colored"> {{ newQuantity }}</span></SharedButtonNew
      >
      <SharedButtonNew
        :form="inventoryButtonFormPresetPicker"
        :color="inventoryBtnColorsPreset"
        :text-props="inventoryItemButtnTextPresetPicker"
        :state="{ active: state === 'old', disabled: disableOldBtn }"
        @click="changeState('old')"
      >
        {{ t('inventoryOld') }}
        <span v-if="!isPendingOld" class="button-text__colored">
          {{ oldQuantity || 0 }}
        </span></SharedButtonNew
      >
    </div>
    <div v-if="hasSellPrice && state === 'new' && newQuantity > 0" class="menu-buttons__right">
      <SharedButtonNew
        v-if="isShowBtnSell"
        :form="inventorySellButtonFromPresetPicker"
        :color="inventoryMenuSellButtonColorPreset"
        :text-props="inventorySellButtnTextPresetPicker"
        :state="{ disabled: isPendingSellAll }"
        @click="$emit('on-sell-all')"
        >{{ t('inventorySellAll') }}
        {{ useFormatCurrency(sellPrice, currency as TCurrencyType, true) }}
      </SharedButtonNew>
    </div>
  </div>
</template>
<script setup lang="ts">
import type { TCurrencyType } from '~/types/Shared.types';
import {
  bigInventoryMenuSellButtonTextPreset,
  bigMenuItemBtnTextPreset,
  inventoryMenuItemsBigButtonsFormPreset,
  inventoryMenuItemsButtonsColorPreset,
  inventoryMenuItemsSmallButtonsFormPreset,
  inventoryMenuSellBigButtonFormPreset,
  inventoryMenuSellButtonColorPreset,
  inventoryMenuSellMediumButtonFormPreset,
  inventoryMenuSellSmallButtonFormPreset,
  smallInventoryMenuSellButtonTextPreset,
  smallMenuItemBtnTextPreset,
} from '~/features/bk/components/BKIntegrationInventoryMenu/BKIntegrationInventoryMenu.data';
import type {
  IBKIntegrationInventoryMenuEmits,
  IBKIntegrationInventoryMenuProps,
  TInventoryBtnState,
} from '~/features/bk/components/BKIntegrationInventoryMenu/BKIntegrationInventoryMenu.types';
import { Breakpoints } from '~/constants/media.constants';

const emits = defineEmits<IBKIntegrationInventoryMenuEmits>();

const props = withDefaults(defineProps<IBKIntegrationInventoryMenuProps>(), {
  currency: '₽',
  inventoryBtnColorsPreset: () => inventoryMenuItemsButtonsColorPreset,
  newQuantity: 34,
  oldQuantity: 13313,
  sellPrice: 467.67,
});

const hasSellPrice = computed<boolean>(() => props.sellPrice > 0);

const { t } = useI18n();

const { currency, newQuantity, oldQuantity } = toRefs(props);

const state = ref<TInventoryBtnState>('new');

const changeState = (newState: TInventoryBtnState) => {
  state.value = newState;
  emits('on-change-state', newState);
};

const viewport = useViewport();

const inventoryButtonFormPresetPicker = computed(() => {
  return viewport.isLessThan(Breakpoints.xl)
    ? inventoryMenuItemsSmallButtonsFormPreset
    : inventoryMenuItemsBigButtonsFormPreset;
});

const inventorySellButtonFromPresetPicker = computed(() => {
  if (viewport.isLessThan(Breakpoints.md)) {
    return inventoryMenuSellSmallButtonFormPreset;
  }
  return viewport.isLessThan(Breakpoints.xl)
    ? inventoryMenuSellMediumButtonFormPreset
    : inventoryMenuSellBigButtonFormPreset;
});

const inventorySellButtnTextPresetPicker = computed(() => {
  return viewport.isGreaterThan(Breakpoints.md)
    ? bigInventoryMenuSellButtonTextPreset
    : smallInventoryMenuSellButtonTextPreset;
});

const inventoryItemButtnTextPresetPicker = computed(() => {
  return viewport.isGreaterThan(Breakpoints.md) ? bigMenuItemBtnTextPreset : smallMenuItemBtnTextPreset;
});
</script>
<style scoped src="./BKIntegrationInventoryMenu.scss"></style>
