import type { TCurrencyType } from '~/components/SharedPrice/SharedPrice.types';
import { useBkIntegratorStore } from '~/features/bk/store/bk.integrator';
import type { IInventoryItem } from '~/repository/modules/bk/bk.types';
import { AlertCodes } from '~/store/alert/alert.messages';
import { useAlertStore } from '~/store/alert/alert.store';

export const useDemoStore = defineStore('bk/demo', () => {
  const store = useBkIntegratorStore();
  const alertStore = useAlertStore();
  const { urlOptions } = storeToRefs(store);

  const isDemo = computed(() => urlOptions.value.isDemo);

  const balance = ref<number>(10000);

  const currency = ref<TCurrencyType>('DM');

  const inventory = ref<IInventoryItem[]>([]);

  const reduceBalance = (value: string) => {
    balance.value -= +value;
  };

  const increaseBalance = (value: string | number) => {
    balance.value += +value;
  };

  const addToInventory = (item: IInventoryItem) => {
    const newItem = { ...item, inactive: false, takeAvailable: true, taken: false } as IInventoryItem;

    inventory.value.unshift(newItem);
  };

  const removeFromInventory = (itemId: number | string) => {
    const itemIndex = inventory.value.findIndex(
      (item) => String(item.id) === String(itemId) && item.takeAvailable === true,
    );
    if (!inventory.value[itemIndex]) return;
    inventory.value[itemIndex].takeAvailable = false;
    inventory.value[itemIndex].taken = false;
    inventory.value[itemIndex].isOld = true;
  };

  const totalInventoryPrice = computed(() => {
    return inventory.value.reduce((acc, item) => (!item?.isOld ? acc + +item.priceData.price : acc), 0);
  });

  const removeAllItemsFromInventory = () => {
    inventory.value.forEach((item) => {
      item.takeAvailable = false;
      item.taken = false;
      item.isOld = true;
    });
  };

  const sellWonItem = (item: IInventoryItem) => {
    removeFromInventory(item.id);
    increaseBalance(item.priceData.price);
    alertStore.show({
      title: AlertCodes.ITEM_IN_SALE_QUEUE,
      type: 'success',
    });
  };

  const getNewItemsQuantity = computed<number>(() => {
    return inventory.value.filter((item) => !item?.isOld).length;
  });

  const getOldItemsQuantity = computed<number>(() => {
    return inventory.value.filter((item) => item?.isOld).length;
  });

  const sellAllItems = () => {
    increaseBalance(totalInventoryPrice.value);
    removeAllItemsFromInventory();
    alertStore.show({
      title: AlertCodes.ALL_ITEMS_IN_SALE_QUEUE,
      type: 'success',
    });
  };

  return {
    addToInventory,
    balance,
    currency,
    getNewItemsQuantity,
    getOldItemsQuantity,
    increaseBalance,
    inventory,
    isDemo,
    reduceBalance,
    removeAllItemsFromInventory,
    removeFromInventory,
    sellAllItems,
    sellWonItem,
    totalInventoryPrice,
  };
});
