<template>
  <div :class="rootClasses" :style="rootStyles">
    <div class="sharedDropItem__content">
      <div class="sharedDropItem__shadow" />
      <div v-if="props.selected && !isPreview" class="sharedDropItem__selected">
        <div class="sharedDropItem__selected_checkmark">
          <SvgoCheckmarkIcon />
        </div>
      </div>
      <div v-if="props.selected && !areThereItemOptions && !isPreview" class="card__remove">
        <component :is="props.type === 'upgrade' ? 'SvgoUpgradeRemoveIcon' : 'SvgoContractRemoveIcon'" />
      </div>
      <div v-if="!props.selected && !areThereItemOptions && !isPreview" class="card__upgrade">
        <component :is="props.type === 'upgrade' ? 'SvgoUpgradePlusIcon' : 'SvgoContractPlusIcon'" />
      </div>
      <div v-if="$slots.hover" class="sharedDropItem__hover">
        <slot name="hover"></slot>
      </div>
      <div class="sharedDropItem__card">
        <div class="sharedDropItem__image_container">
          <div class="sharedDropItem__image_shadow" />
          <SharedImage
            v-if="isMounted && props.image"
            :img="props.image"
            class="sharedDropItem__image"
            fit="cover"
            auto-size
          />
        </div>
        <div v-if="!props?.topLine?.isHide && (props.itemPrice || $slots.lineTop)" class="card__line_top">
          <slot name="lineTop">
            {{ props.itemPrice?.price || '0' }}
            <UserController>
              <template #default="{ isFake }">
                <SharedPrettyCurrency :is-fake="isFake" :currency="currentCurrency" :custom-currency="customCurrency" />
              </template>
            </UserController>
            <SharedText
              v-if="props.priceToCompare"
              :color="!parseFloat(comparePrice!) ? Colors.ADDITIONAL.GREEN : Colors.ADDITIONAL.RED"
              as="span"
            >
              {{ comparePrice }}
              <UserController>
                <template #default="{ isFake }">
                  <SharedPrettyCurrency
                    :is-fake="isFake"
                    :currency="currentCurrency"
                    :custom-currency="customCurrency"
                  />
                </template>
              </UserController>
            </SharedText>

            <SharedText
              v-if="isStatTrak"
              class="lineTop__rarity"
              :color="Colors.RARITY.ANCIENT"
              :size="FontSizes.SMALL_XS"
              :weight="FontWeights.BLACK"
            >
              ST
            </SharedText>
          </slot>
        </div>
        <div v-if="parsedType" class="sharedDropItem__steam-icon">
          <SvgoDarkSteamIcon />
        </div>
        <div v-if="!props?.bottomLine?.isHide && (props.itemName || $slots.lineBottom)" class="card__line_bottom">
          <slot name="lineBottom">
            <ClientOnly>
              <SharedText
                v-bind="props.bottomLine?.textProps || {}"
                :class="[isTransparentTitle && 'transparent-title', 'skin-name']"
              >
                {{ props.itemName }}
              </SharedText>
              <div class="card__info">
                <span v-if="quality && quality.qualityShort" class="quality">
                  <SharedQuality v-bind="quality" as="span" />
                </span>
                <span v-if="itemSkin">
                  <SharedText as="span" inline-text>
                    {{ itemSkin }}
                  </SharedText>
                </span>
              </div>
            </ClientOnly>
          </slot>
        </div>
        <transition name="profile-options">
          <div
            v-if="areThereItemOptions && areProfileOptionsVisible && !isSmall && !props.unavailable && !slots.hover"
            class="card__options"
          >
            <ItemOptions
              :list="props.itemOptions"
              :text-props="{ color: Colors.NEUTRAL.WHITE, size: FontSizes.SMALL_2XS, weight: 'var(--bold)' }"
            />
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { type CSSProperties, toRefs } from 'vue';
import { computed, ref } from 'vue';
import SharedImage from '../SharedImage/SharedImage.vue';
import type { IPropsSharedDropItem } from './SharedDropItem.types';
import ItemOptions from '~/components/SharedDropItem/ProfileOptions/ItemOptions.vue';
import { FontSizes, FontWeights } from '~/types/SharedFont.types';
import { Colors } from '~/constants/colors.constants';
import type { TCurrencyType } from '~/components/SharedPrice/SharedPrice.types';

const BOTTOM_LINE_HEIGHT_VAR = '--bottom-line-height';
const DROP_ITEM_MAIN_COLOR_VAR = '--item-main-color';
const TOP_LINE_BACKGROUND_IMAGE_VAR = '--top-line-background-image';
const TOP_LINE_COLOR_VAR = '--top-line-bg-color';
const TOP_LINE_HEIGHT_VAR = '--top-line-height';
const BOX_SHADOW_COLOR_VAR = '--item-box-shadow-color';
const SIDE_STROKES_WIDTH_VAR = '--side-strokes-width';
const BORDER_COLOR = '--border-color';
const BORDER_IMAGE = '--border-image';
const OVERLAY = '--default-overlay';

const COMPONENT_NAME = 'sharedDropItem';

const { proceedCssValue } = GlobalUtils.CSS;

const slots = defineSlots<{
  hover(props: unknown): unknown;
  lineBottom(props: unknown): unknown;
  lineTop(props: unknown): unknown;
}>();

const props = withDefaults(defineProps<Partial<IPropsSharedDropItem>>(), {
  activeBgColor: '5 232 0',
  activeIconBgColor: 'var(--additional-green)',
  borderColor: 'var(--additional-green)',
  checkmarkColor: Colors.NEUTRAL.WHITE,
  condition: 'FD',
  customCurrency: '',
  disableAnimation: false,
  disableShadow: false,
  hideBottomLine: false,
  hideTopLine: false,
  isTransparentTitle: false,
  itemOptions: () => [],
  mainColor: Colors.RARITY.ULTRA,
  overlay: 'var(--neutral-black)',
  previewOnly: false,
  profileOptions: false,
  selected: true,
  size: 'medium',
  type: 'upgrade',
  unavailable: false,
});

const { itemType } = toRefs(props);

const isSmall = computed(() => props.size === 'small');
const areThereItemOptions = computed(() => !!props.itemOptions.length);
const isPreview = computed(() => props.previewOnly);
const areProfileOptionsVisible = ref(false);

const parsedType = computed(() => Number(itemType.value ?? 0) === 3);

const rootClasses = computed(() => ({
  [COMPONENT_NAME]: true,
  [`${COMPONENT_NAME}--${props.size}`]: props.size,
  [`${COMPONENT_NAME}--selected`]: props.selected && !isPreview.value,
  [`${COMPONENT_NAME}--${props.type}`]: props.type && !isSmall.value && !isPreview.value,
  [`${COMPONENT_NAME}--unavailable`]: props.unavailable && !isPreview.value,
  [`${COMPONENT_NAME}--no-animation`]: props.disableAnimation,
  [`${COMPONENT_NAME}--profile-options`]: areThereItemOptions.value && !isSmall.value && !isPreview.value,
  [`${COMPONENT_NAME}--blurred`]: !!slots.hover && !isPreview.value,
  [`${COMPONENT_NAME}--no-shadow`]: props.disableShadow,
  [`${COMPONENT_NAME}--preview-only`]: isPreview.value,
  [`${COMPONENT_NAME}--no-top-line`]: props.hideTopLine,
  [`${COMPONENT_NAME}--no-bottom-line`]: props.hideBottomLine,
}));
const rootStyles = computed<CSSProperties>(() => {
  const properties: CSSProperties = {};

  if (props?.topLine?.backgroundImage) properties[TOP_LINE_BACKGROUND_IMAGE_VAR] = props.topLine.backgroundImage;
  if (props.sideStrokesWidth) properties[SIDE_STROKES_WIDTH_VAR] = proceedCssValue(props.sideStrokesWidth);
  if (props?.bottomLine?.height) properties[BOTTOM_LINE_HEIGHT_VAR] = proceedCssValue(props.bottomLine.height);
  if (props?.topLine?.height) properties[TOP_LINE_HEIGHT_VAR] = proceedCssValue(props.topLine.height);
  if (props?.topLine?.color) properties[TOP_LINE_COLOR_VAR] = proceedCssValue(props.topLine.color);
  if (props.mainColor) properties[DROP_ITEM_MAIN_COLOR_VAR] = props.mainColor;
  if (props.borderImage) properties[BORDER_IMAGE] = props.borderImage;
  if (props.overlay) properties[OVERLAY] = props.overlay;
  if (props.boxShadowColor) properties[BOX_SHADOW_COLOR_VAR] = proceedCssValue(props.boxShadowColor);
  if (props.boxShadowColor) properties[BOX_SHADOW_COLOR_VAR] = proceedCssValue(props.boxShadowColor);
  if (props.boxShadowColor) properties[BOX_SHADOW_COLOR_VAR] = proceedCssValue(props.boxShadowColor);
  if (props.borderColor) properties[BORDER_COLOR] = proceedCssValue(props.borderColor);
  properties['--active-bg'] = proceedCssValue(props.activeBgColor);
  properties['--active-icon-bg'] = proceedCssValue(props.activeIconBgColor);
  properties['--checkmark-color'] = proceedCssValue(props.checkmarkColor);
  return properties;
});

const comparePrice = computed<string>(() => {
  if (!props.itemPrice || !props.priceToCompare) return '';
  const differenceCompareWithItemPrice = Number(props.priceToCompare) - Number(props.itemPrice.price);
  return GlobalUtils.Prices.toFixedDefault(differenceCompareWithItemPrice);
});

const currentCurrency = computed<TCurrencyType | undefined>(() => {
  return props.itemPrice?.currency;
});

const isMounted = ref(false);
onMounted(() => {
  isMounted.value = true;

  if (window.innerWidth > 768) areProfileOptionsVisible.value = true;
});
</script>

<style src="./SharedDropItem.scss" lang="scss" scoped></style>
