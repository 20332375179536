import type { TStatuses } from '~/types/cases/status';
import type { IAlert } from '~/store/alert/alert.types';
import { AlertCodes } from '~/store/alert/alert.messages';
// Получить информацию об изменении статуса
function getAlertStatus(status: TStatuses): IAlert | undefined {
  switch (status) {
    case 'accepted':
      return {
        title: AlertCodes.ITEM_RECEIVED,
        type: 'success',
      };
    case 'canceled':
      return {
        title: AlertCodes.ITEM_TRANSFER_CANCELED,
        type: 'success',
      };
    case 'send':
      return {
        title: AlertCodes.ITEM_IN_WITHDRAWAL_QUEUE,
        type: 'success',
      };
    case 'error':
      return {
        title: AlertCodes.ERROR_WITHDRAWAL,
        type: 'error',
      };
  }
}

export { getAlertStatus };
