import { useTakeItems } from '~/composables/useTakeItems';
import type { IBkInventory, IInventoryItem } from '~/repository/modules/bk/bk.types';
import type { ISetTakeItemState } from '~/features/bk/types/takeItems.types';

/** Кастомный метод работы с таймером и состоянием предмета
 * Не рекомендуется к глобальному использованию **/
export function useBkTakeItems(inventory: Ref<IBkInventory<IInventoryItem>> | Ref<undefined>) {
  const { changeItemState } = useTakeItems();

  /** Изменить состояние предмета и установить таймер
   * За изучением деталей смотри в функцию changeItemState  **/
  const setTakeItemState = (arg: ISetTakeItemState) => {
    if (!inventory.value?.items.length) return;
    const { timerData, takeItemState } = changeItemState(arg);

    inventory.value.items = inventory.value?.items.map((item) => {
      if (+item.id === +arg.id) {
        return {
          ...item,
          takeItemState,
          timerData,
        };
      }
      return item;
    }) as IInventoryItem[];
  };

  return {
    changeItemState,
    setTakeItemState,
  };
}
