<template>
  <div class="main">
    <div class="wrapper">
      <SharedText v-bind="titleStylePicker">{{ t('inventory') }}</SharedText>

      <!-- eslint-disable-next-line vue/no-v-text-v-html-on-component, vue/no-v-html -->
      <SharedText class="modal-description" v-bind="descriptionStyle" v-html="topModalDescription" />
      <div class="wrapper__inputs">
        <SharedField
          v-model.trim="steamUrl"
          :class="{ demo: isDemo }"
          :text-align="'center'"
          :width="inputWidthPicker"
          :height="inputHeightPicker"
          :placeholder-position="'center'"
          :options-placeholder="placeholderStyle"
          v-bind="{ ...steamTradeUrlValidation, ...inputStyle }"
          :placeholder="t('steamUrlPlaceholder')"
          :disabled="isDemo || isPendingLink"
          @on-input-blur="handleUrlBlur"
        />
        <SharedField
          v-model.trim="steamLogin"
          v-bind="{ ...steamLoginValidation, ...inputStyle }"
          :class="{ demo: isDemo }"
          :text-align="'center'"
          :width="inputWidthPicker"
          :height="inputHeightPicker"
          :placeholder-position="'center'"
          :options-placeholder="placeholderStyle"
          :placeholder="t('steamLoginPlaceholder')"
          :disabled="isDemo || isPendingLogin"
          @on-input-blur="handleLoginBlur"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import type { VueI18nTranslation } from 'vue-i18n';
import type {
  ITopModalEmit,
  ITopModalProps,
  ITopModalValidationRes,
} from '~/features/bk/components/IFrameModalTop/IFrameModalTop.types';
import { titleStyle, titleStyleDesktop } from '~/features/bk/components/IFrameSlider/IFrameSlider.data';
import {
  descriptionDefaultStyle,
  descriptionMobileDefaultStyle,
  inputDefaultStyle,
  inputMobileDefaultStyle,
  placeholderDefaultStyle,
  placeholderMobileDefaultStyle,
} from '~/features/bk/components/IFrameModalTop/IFrameModalTop.Data';
import { Colors } from '~/constants/colors.constants';
import BkUtils from '~/features/bk/utils';

import { validateSteamLogin } from '~/utils/steam.utils';
import { Breakpoints } from '~/constants/media.constants';

const { t } = useI18n();

const topModalDescription = BkUtils.Text.transformTextBk({
  str: t('inventoryTopText'),
  styledWords: [
    'ссылку на обмен',
    'логин Steam',
    'trade link',
    'Steam login',
    'сауда URL',
    'Steam логинін',
    'ट्रेड URL',
    'स्टीम लॉगिन',
    'linki mübadilə',
    'Steam-i',
  ],
  styledWordsLinks: [
    { link: 'https://steamcommunity.com/id/me/tradeoffers/privacy/#trade_offer_access_url', word: 'ссылку на обмен' },
    { link: 'https://store.steampowered.com/account/', word: 'логин Steam' },
    { link: 'https://steamcommunity.com/id/me/tradeoffers/privacy/#trade_offer_access_url', word: 'trade link' },
    { link: 'https://store.steampowered.com/account/', word: 'Steam login' },
    {
      link: 'https://steamcommunity.com/id/me/tradeoffers/privacy/#trade_offer_access_url',
      word: 'сауда URL',
    },
    { link: 'https://store.steampowered.com/account/', word: 'Steam логинін' },
    {
      link: 'https://steamcommunity.com/id/me/tradeoffers/privacy/#trade_offer_access_url',
      word: 'ट्रेड URL',
    },
    { link: 'https://store.steampowered.com/account/', word: 'स्टीम लॉगिन' },
    { link: 'https://steamcommunity.com/id/me/tradeoffers/privacy/#trade_offer_access_url', word: 'linki mübadilə' },
    { link: 'https://store.steampowered.com/account/', word: 'Steam-i' },
  ],
  styles: Colors.BK_INTEGRATION.BALANCE_GREEN,
});

const props = withDefaults(defineProps<ITopModalProps>(), {
  isDemo: false,
  isPendingLink: false,
  isPendingLogin: false,
  steamLogin: '',
  steamUrl: '',
});
const emit = defineEmits<ITopModalEmit>();

const steamTradeUrlModel = ref<string>(props.steamUrl);
const steamLoginModel = ref<string>(props.steamLogin);

const steamUrl = computed<string>({
  get() {
    return props.steamUrl;
  },
  set(value: string) {
    steamTradeUrlModel.value = value;
  },
});

const steamLogin = computed<string>({
  get() {
    return props.steamLogin;
  },
  set(value: string) {
    steamLoginModel.value = value;
  },
});

watchEffect(() => {
  steamTradeUrlModel.value = steamUrl.value;
  steamLoginModel.value = steamLogin.value;
});

const steamTradeUrlValidation = ref<ITopModalValidationRes>({ isValid: true });
const steamLoginValidation = ref<ITopModalValidationRes>({ isValid: true });

const handleUrlBlur = () => {
  steamTradeUrlValidation.value = validator(validateSteamTradeUrl, steamTradeUrlModel.value, t);
  emit('update:steam-url', { ...steamTradeUrlValidation.value, steamTradeUrl: steamTradeUrlModel.value });
};
const handleLoginBlur = () => {
  steamLoginValidation.value = validator(validateSteamLogin, steamLoginModel.value, t);
  emit('update:steam-login', { ...steamLoginValidation.value, steamLogin: steamLoginModel.value });
};

function validator(
  cb: (value: string, localizator: VueI18nTranslation) => ITopModalValidationRes,
  arg: string,
  localizator: VueI18nTranslation,
) {
  return cb(arg, localizator);
}

const viewport = useViewport();
const titleStylePicker = computed(() => {
  return viewport.isGreaterThan(Breakpoints.md) ? titleStyleDesktop : titleStyle;
});

const inputHeightPicker = computed(() => {
  return viewport.isGreaterThan(Breakpoints.md) ? '40px' : '32px';
});

const inputWidthPicker = computed(() => {
  const sizes = {
    md: '204px',
    sm: '312px',
    xl: '280px',
    xxl: '280px',
  };

  return sizes[viewport.breakpoint.value as keyof typeof sizes];
});

const descriptionStyle = computed(() => {
  return viewport.isGreaterOrEquals(Breakpoints.xl) ? descriptionDefaultStyle : descriptionMobileDefaultStyle;
});
const inputStyle = computed(() => {
  return viewport.isGreaterOrEquals(Breakpoints.xl) ? inputDefaultStyle : inputMobileDefaultStyle;
});

const placeholderStyle = computed(() => {
  return viewport.isGreaterOrEquals(Breakpoints.xl) ? placeholderDefaultStyle : placeholderMobileDefaultStyle;
});
</script>

<style src="./IFrameModalTop.scss" scoped lang="scss"></style>
