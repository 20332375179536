import type {
  ISharedButtonNewColorPreset,
  ISharedButtonNewFormPreset,
  TSharedButtonTextPreset,
} from '~/types/SharedButtonNew.types';
import {
  defaultButtonColorPreset,
  defaultMiddleButtonFormPreset,
} from '~/components/SharedButtonNew/SharedButtonNew.data';
import { FontSizes } from '~/types/SharedFont.types';

export const inventoryMenuItemsSmallButtonsFormPreset: ISharedButtonNewFormPreset = {
  ...defaultMiddleButtonFormPreset,
  height: '40px',
  minWidth: '50%',
  radius: '0',
  width: '50%',
};

export const inventoryMenuItemsBigButtonsFormPreset: ISharedButtonNewFormPreset = {
  ...defaultMiddleButtonFormPreset,
  height: '50px',
  minWidth: '200px',
  radius: '0',
  width: '200px',
};

export const inventoryMenuItemsButtonsColorPreset: ISharedButtonNewColorPreset = {
  ...defaultButtonColorPreset,
  color: 'var(--main-color-100)',
  colorHovered: 'var(--main-color-50)',
  colorInactive: 'var(--main-color-200)',
};

export const inventoryMenuSellSmallButtonFormPreset: ISharedButtonNewFormPreset = {
  ...defaultMiddleButtonFormPreset,
  height: '40px',
  margin: 'var(--gap-2xs) 0 0 0',
  minWidth: '240px',
  width: '240px',
};

export const inventoryMenuSellMediumButtonFormPreset: ISharedButtonNewFormPreset = {
  ...inventoryMenuSellSmallButtonFormPreset,
  // вынужденная мера в силу использования общего компонента для новых/старых предметов
  // у которых отличается паддинг между кнопками и предметами на адаптипе
  margin: 'var(--gap-2xs) 0 -8px 0',
};

export const inventoryMenuSellBigButtonFormPreset: ISharedButtonNewFormPreset = {
  ...defaultMiddleButtonFormPreset,
  height: '50px',
  minWidth: '324px',
  radius: '0',
  width: '324px',
};

export const inventoryMenuSellButtonColorPreset: ISharedButtonNewColorPreset = {
  ...defaultButtonColorPreset,
  color: 'var(--bk-integration-green)',
  colorHovered: 'var(--neutral-white)',
};

export const smallInventoryMenuSellButtonTextPreset: TSharedButtonTextPreset = {
  color: 'var(--neutral-black)',
  size: FontSizes.MEDIUM,
};

export const bigInventoryMenuSellButtonTextPreset: TSharedButtonTextPreset = {
  color: 'var(--neutral-black)',
  size: FontSizes.LARGE,
};

export const smallMenuItemBtnTextPreset: TSharedButtonTextPreset = {
  color: 'var(--neutral-white)',
  size: FontSizes.MEDIUM,
};

export const bigMenuItemBtnTextPreset: TSharedButtonTextPreset = {
  color: 'var(--neutral-white)',
  size: FontSizes.LARGE,
};
