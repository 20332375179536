export enum EBkEvents {
  BK_CASE_ENTERED = 'BK - Case Entered',
  BK_CASE_LEAVE = 'BK - Case Leave',
  BK_CASE_OPENED = 'BK - Case Opened',
}

export interface IBkCaseEnteredEvent {
  'BK Name': string;
  'Case ID': number | string;
  'Case Name': string;
  'Case Price': number;
  'Case Type': string;
  'Open Mode': 'demo' | 'money';
}

export interface IBkCaseLeaveEvent {
  'BK Name': string;
  'Case ID': number | string;
  'Case Name': string;
  'Case Out': number;
  'Case Price': number;
  'Case Type': string;
}

export interface IBkCaseOpenedDemoEvent {
  'BK Name': string;
  'Case ID': number | string;
  'Case Name': string;
  'Case Price': number;
  'Case Type': string;
  'Fast Open': boolean;
  'Open Mode': 'demo';
}

export interface IBkCaseOpenedMoneyEvent {
  'BK Name': string;
  'Case ID': number | string;
  'Case Name': string;
  'Case Price': number;
  'Case Type': string;
  'Fast Open': boolean;
  'Item ID': number | string;
  'Item Name': string;
  'Item Price': number;
  'Item Rarity': string;
  'Open Mode': 'money';
  'User Profit in Percent': string;
  'User Profit in RUB': number;
}

export type TBkCaseOpenedEvent = IBkCaseOpenedDemoEvent | IBkCaseOpenedMoneyEvent;
