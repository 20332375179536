import { Colors } from '~/constants/colors.constants';
import { BorderRadius } from '~/constants/common.constants';
import type { ITextProps } from '~/types/SharedFont.types';
import { FontSizes, FontSizesNumber, FontWeights } from '~/types/SharedFont.types';

export const descriptionDefaultStyle = {
  color: Colors.NEUTRAL.WHITE_50,
  cursor: 'pointer',
  size: FontSizes.LARGE,
  weight: FontWeights.MEDIUM,
};
export const descriptionMobileDefaultStyle = {
  color: Colors.NEUTRAL.WHITE_50,
  cursor: 'default',
  size: FontSizes.MEDIUM,
};

export const inputDefaultStyle = {
  borderRadius: BorderRadius.XS,
  fontSize: FontSizesNumber.LARGE,
  fontWeight: FontWeights.MEDIUM,
};
export const inputMobileDefaultStyle = {
  borderRadius: BorderRadius.XS3,
  fontSize: FontSizesNumber.SMALL,
  fontWeight: FontWeights.MEDIUM,
};
export const placeholderDefaultStyle: ITextProps = {
  color: Colors.NEUTRAL.WHITE,
  size: FontSizesNumber.LARGE,
  weight: FontWeights.MEDIUM,
};
export const placeholderMobileDefaultStyle: ITextProps = {
  size: FontSizesNumber.SMALL,
  weight: FontWeights.MEDIUM,
};
