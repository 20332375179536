<template>
  <div class="content" :style="varCss">
    <CaseLoader v-if="isPendingCase" class="content__loader" />
    <template v-else>
      <SharedText :size="textSize" v-bind="titleStyle" class="content__title">{{ t('caseContentTitle') }}</SharedText>
      <BkCaseDropList
        :top-line-color="topLineColor"
        :authorized="true"
        :items="sortedCases"
        :custom-currency="demoStore.isDemo ? demoStore.currency : ''"
        :extra-items="ultraCases"
        class="content__cases"
      />
    </template>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useBkCasesStore } from '../../store/bk.cases';
import CaseLoader from '~/features/cases/components/CaseLoader/CaseLoader.vue';
import type { IBkCaseContent } from '~/features/bk/components/IFrameCaseContent/IFrameCaseContent.types';
import { Colors } from '~/constants/colors.constants';
import { titleStyle } from '~/features/bk/components/IFrameSlider/IFrameSlider.data';
import { useDemoStore } from '~/features/bk/store/demo';
import BkCaseDropList from '~/features/bk/components/BkCaseDropList/BkCaseDropList.vue';
import { Breakpoints } from '~/constants/media.constants';
import { FontSizes } from '~/types/SharedFont.types';

const viewport = useViewport();

const props = withDefaults(defineProps<IBkCaseContent>(), {
  bgColor: 'var(--main-color-500)',
  scrollColor: Colors.NEUTRAL.WHITE_15,
  topLineColor: Colors.BK_INTEGRATION.TOP_PRICE_PURPLE,
});

const textSize = computed(() => {
  return viewport.isGreaterOrEquals(Breakpoints.md) ? FontSizes.LARGE_2XL : FontSizes.LARGE_XL;
});

const { t } = useI18n();

const varCss = computed<Record<string, string>>(() => {
  return {
    '--bg-color': props.bgColor,
    '--scroll-color': props.scrollColor,
  };
});

const store = useBkCasesStore();
const { sortedCases, ultraCases, isPendingCase } = storeToRefs(store);
const demoStore = useDemoStore();
</script>

<style src="./IFrameCaseContent.scss" scoped lang="scss"></style>
