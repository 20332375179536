import { defineStore } from 'pinia';
import type { IPopupsState } from '~/features/bk/types/localStore.types';

export const useLocalIFrameStore = defineStore('bk/localIframe', () => {
  const popups = ref<IPopupsState>({
    caseContent: false,
    change: false,
    demoGetItems: false,
    error: false,
    gameInfo: false,
    inventory: false,
    won: false,
  });

  const openPopup = (name: string) => {
    // @ts-expect-error TODO remove or refactor
    popups.value[name] = true;
  };

  const closePopup = (name: string) => {
    // @ts-expect-error TODO remove or refactor
    popups.value[name] = false;
  };

  const currentSlide = ref<number>(1);

  const eventColor = ref<string>('var(--main-color-600)');

  const priceLineColor = ref<string>('var(--bk-integration-purple)');

  // состояние анимации рулетки
  const isCarouselAnimation = ref(false);

  // изменение состояния анимации
  const changeAnimation = (val: boolean) => {
    isCarouselAnimation.value = val;
  };

  return {
    changeAnimation,
    closePopup,
    currentSlide,
    eventColor,
    isCarouselAnimation,
    openPopup,
    popups,
    priceLineColor,
  };
});
