<template>
  <div class="item-options">
    <div
      v-for="point in props.list"
      :key="point.role"
      class="item-options__option"
      :style="{ backgroundColor: point.color, height: GlobalUtils.CSS.proceedCssValue(point.height as string) }"
    >
      <div class="item-options__option_icon">
        <component :is="point.icon" filled></component>
      </div>
      <SharedText class="item-options__option_text" align="center" v-bind="props.textProps || {}">
        {{ point.text }}
      </SharedText>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { IPropsItemOptions } from './ItemOptions.types';

const props = defineProps<IPropsItemOptions>();
</script>

<style src="./ItemOptions.scss" lang="scss" scoped></style>
