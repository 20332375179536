import { defineStore, skipHydrate } from 'pinia';
import type { Ref } from 'vue';
import { useBkIntegratorStore } from './bk.integrator';
import { useBkInventoryStore } from './bk.inventory';
import type { Types } from '~/repository/modules/cases';
import type {
  ICaseData,
  IImageData,
  IOpenItemBk,
  IOpenItemsBk,
  ISection,
} from '~/repository/modules/cases/cases.types';
import { useAlertStore } from '~/store/alert/alert.store';
import { ErrorCodes } from '~/api/global/errors/codes/codes';
import { useBkModalStore } from '~/features/bk/store/bk.modal';
import { useBkErrorStore } from '~/features/bk/store/bk.error';
import { ComponentNames } from '~/features/bk/constants/index.components';
import MessageError from '~/api/global/errors/messages/MessageError';
import { useIntegratorApi } from '~/features/bk/composables/useIntegratorApi';
import type { BkCaseResponseDto } from '~/repository/modules/bk/bkCases.dto';
import type {
  IBkCaseDropItem,
  IBkCaseDropItemDto,
  IBkItemCaseData,
  IBkItemCaseDataDto,
} from '~/repository/modules/bk/bkCases.types';
import { GlobalUtils } from '~/utils';
import type { IError } from '~/repository/extensions/error/error.types';
import type { TCaseDropItem, ITypeDescriptionCase } from '~/features/cases/types/case.types';
import { useBkUserStore } from '~/features/bk/store/bk.user';
import { useGlobalErrors } from '~/store/error/error';
import { BkEvents } from '~/repository/amplitude/events/bk';

/**
 * Сортирует массив по цене
 * @param {Array} arr - исходный массив
 * @returns {Array} - отсортированный массив
 */
const sortArrayByPrice = <T extends (TCaseDropItem | ICaseData | IBkCaseDropItem)[]>(arr: T): T => {
  return arr.sort((a, b) => +a.priceData.price - +b.priceData.price);
};

export const useBkCasesStore = defineStore('bk/cases', () => {
  // import
  const {
    $api,
    $i18n: { t },
  } = useNuxtApp();

  const route = useRoute();
  const alertStore = useAlertStore();
  const errorsStore = useGlobalErrors();
  const bkErrorStore = useBkErrorStore();
  const bkModalStore = useBkModalStore();

  const storeBk = useBkIntegratorStore();
  const userBK = useBkUserStore();
  const { urlOptions } = storeToRefs(storeBk);
  const inventoryStore = useBkInventoryStore();
  const { addItemToNewInventory } = inventoryStore;

  const { defaultUrl, createAuthUrl, computedLanguageForCase } = useIntegratorApi(urlOptions);

  // const
  const QUEUE_ORDER: string[] = ['ARCANA', 'IMMORTAL', 'LEGENDARY', 'MYTHICAL', 'RARE', 'UNCOMMON', 'COMMON', 'ULTRA'];
  const KEY_FIELD_QUEUE_ORDER = 'qualityEnum/name';
  const LIMIT_SHOW_DROP_ITEM = 64;

  const isPending = ref<boolean>(false);
  const isPendingCase = ref<boolean>(false);
  const isError = ref<boolean>(false);
  const dropListFilterUniqItem = ref<IBkCaseDropItem[]>();

  const error = ref();
  // модель для установки получения ошибки баланса
  const errorBalance = ref<boolean>(false);
  // формат отображения описания кейсов в зависимости от типа
  const activeTypeDescriptionCase = ref<ITypeDescriptionCase>();
  // множитель для мульфикс
  const multiplier = ref<number>(1);
  // создан что-бы убрать ререндер рулетки
  const carouselCount = ref<number>(1);
  // предметы текущего кейса
  const dropList = ref<IBkCaseDropItem[]>([]);
  // выигранные предметы
  const dropsWinner = ref<Types.IOpenItemBk[]>([]);
  // id юзера, который отправил ссылку на дроп
  const userLinkId = ref<number>();
  // получить 4 ивентовых кейса
  const eventCases = ref<ICaseData[]>([]);

  const caseBg = ref<IImageData>({} as IImageData);

  // страница текущего кейса
  const itemCase = ref<IBkItemCaseData>({} as IBkItemCaseData);
  // используется для задачи имени из роута
  const caseName = ref<string>('');
  // массив дроп листов, для работы мультирулетки сколько каждый массив под свою рулетку
  const listDropList = ref<IBkCaseDropItem[][]>([]);

  // Индекс элемента с правого края массива будет являться победным
  const idxFromTheRightBorderArrayToWinnerElement = ref<number>(4);

  // массив всех кейсов
  const allCases = ref<ISection[]>([]);

  const startTimeThisCase = ref<Date | null>(null);

  const maxCasePriceForUser = ref<ICaseData>();

  const dropWinnerTastyCoins = ref<number | null>();

  const getAmplitudeData = () => {
    if (!itemCase.value) return;

    const { id, label, priceData, type } = itemCase.value;
    const { nameExternalIntegrator } = userBK.user || { nameExternalIntegrator: '' };

    return {
      'BK Name': nameExternalIntegrator,
      'Case ID': id,
      'Case Name': label,
      'Case Price': +priceData?.price,
      'Case Type': type || 'case',
    };
  };

  onMounted(() => {
    startTimeThisCase.value = new Date();

    const amplitudeData = getAmplitudeData();
    if (!amplitudeData) return;

    BkEvents.bkCaseEntered({
      ...amplitudeData,
      'Open Mode': isDemo.value ? 'demo' : 'money',
    });
  });

  const calculateStandEventAmplitude = () => {
    const amplitudeData = getAmplitudeData();
    if (!startTimeThisCase.value || !amplitudeData) return;

    const lastDate = new Date();
    const outTimeThisCase = Math.floor((+lastDate - +startTimeThisCase.value) / 1000);

    BkEvents.bkCaseLeave({
      ...amplitudeData,
      'Case Out': outTimeThisCase,
    });
  };

  // getters
  // Является ли текущее открытие демо открытием
  const isDemo = computed(() => route.query.is_demo);
  // ультраредкие предметы, не повторяются
  const ultraCases = computed<IBkCaseDropItem[]>(() => {
    const list = dropListFilterUniqItem.value?.filter((el) => el.isUltraRare);
    if (!list) return [];
    return sortArrayByPrice(list).reverse();
  });
  // предметы отсортированы по рарности и цене, без ультраредких
  const sortedCases = computed<IBkCaseDropItem[]>(() => {
    const filterListWithoutUltra = dropListFilterUniqItem.value?.filter((el) => !el.isUltraRare);
    if (!filterListWithoutUltra) return [];
    const sortListPrice = sortArrayByPrice(filterListWithoutUltra)?.reverse();
    return GlobalUtils.Objects.sortArrayWithObjectByKey<IBkCaseDropItem[]>(
      sortListPrice,
      QUEUE_ORDER,
      KEY_FIELD_QUEUE_ORDER,
    );
  });
  // список массивов для мультикаст рулетки
  const computedListDropList = computed<IBkCaseDropItem[][]>(() => listDropList.value);

  // все кейсы объединены в один массив, отсортированы по цене
  const allCasesSortsConcat = computed<ICaseData[]>(() => {
    const temp: ICaseData[] = [];
    allCases.value.forEach((el) => temp.push(...el.cases));

    return sortArrayByPrice(temp);
  });

  const createDropList = (
    list: Ref<(TCaseDropItem | IBkCaseDropItem)[][]>,
    itemList: (TCaseDropItem | IBkCaseDropItem)[],
    max: number,
  ) => {
    if (!itemList.length) return; // На всякий случай, если itemList пустой, иначе цикл будет бесконечно крутиться :)
    let temp: (TCaseDropItem | IBkCaseDropItem)[] = [];
    for (let i = 0; i < LIMIT_SHOW_DROP_ITEM; i += itemList.length) {
      temp = [...temp, ...itemList];
    }
    // Добавляем элементы, пока не достигнем max
    for (let i = 0; i < max; i++) {
      list.value.push(GlobalUtils.Objects.shuffleArray(temp).slice(0, LIMIT_SHOW_DROP_ITEM));
    }
  };

  // будем сбрасывать данные о победных кейсах при начале следующего прокрута
  const resetDropsWinner = () => {
    dropsWinner.value = [];
    dropWinnerTastyCoins.value = null;
  };

  const setCaseName = (name: string) => {
    caseName.value = itemCase?.value ? itemCase?.value?.name : name;
  };

  // methods

  const changeCounterCarousel = () => {
    carouselCount.value = multiplier.value;
    createDropList(listDropList, dropList.value, carouselCount.value - 1);
  };

  function transformTo(obj: IBkItemCaseDataDto): IBkItemCaseData {
    const toData = {};

    Object.assign(toData, {
      descriptionData: obj.descriptionData,
      id: obj.id,
      imageData: obj.images,
      label: obj.label,
      name: obj.name,
      priceData: {
        currency: obj.price.currency,
        price: obj.price.price,
      },
    });

    return toData as IBkItemCaseData;
  }

  const handleUnavailableCase = () => {
    errorsStore.setErrorData({
      description: t('errors.bkGlobalErrorMessage.description'),
      title: t('errors.bkGlobalErrorMessage.title'),
    });
    showError({ statusCode: 500 });
  };

  const setCheapestItemAsWonDrop = (carouselIdx: number) => {
    const carouselDropList = listDropList.value[carouselIdx];
    const [cheapest] = [...carouselDropList].sort((a, b) => Number(a.priceData.price) - Number(b.priceData.price));
    const idxOfWonItem = carouselDropList.length - (idxFromTheRightBorderArrayToWinnerElement.value + 1);
    carouselDropList[idxOfWonItem] = cheapest;
  };

  /**
   * Получает базовый кейс по названию
   * @param {string} caseName - наименование кейса
   * @returns {Promise<void>}
   */
  const getCase = async (caseName: string): Promise<void> => {
    await GlobalUtils.Api.handleRequest(
      async () => {
        listDropList.value = [];
        const response = urlOptions.value.isDemo
          ? await $api.bk.getCaseByNameForDemo<BkCaseResponseDto>(
              defaultUrl.value,
              caseName,
              computedLanguageForCase.value,
            )
          : await $api.bk.getCaseByName<BkCaseResponseDto>({ caseName, createAuthUrl });

        // Если кейс в принципе недоступен, то перекидываем на страницу глобальной ошибки
        if (response?.caseData && !response?.caseData.available) return handleUnavailableCase();

        if (response) {
          itemCase.value = transformTo(response.caseData);
          dropListFilterUniqItem.value = response.dropList.map((el: IBkCaseDropItemDto) => {
            const image = el.imageData;
            const newEl = {};
            Object.assign(newEl, el);
            const name = el.qualityEnum.toUpperCase();
            // @ts-expect-error TODO remove or refactor
            newEl.qualityEnum = {
              name,
            };
            if ('imageData' in newEl && typeof newEl.imageData === 'string') newEl.imageData = { image };
            return newEl as IBkCaseDropItem;
          });
        }

        // делаю map для преобразования ответа к нужному формату
        const showedDropList: IBkCaseDropItem[] = [
          ...(dropListFilterUniqItem.value ?? []),
          ...(dropListFilterUniqItem.value ?? []),
        ].slice(0, LIMIT_SHOW_DROP_ITEM);

        dropList.value = showedDropList;
        caseBg.value = response?.caseData?.images || ({} as IImageData);
        if (response && response.dropList) {
          dropList.value = showedDropList;
        }
        multiplier.value = 1;
        createDropList(listDropList, dropList.value, multiplier.value);
        setCheapestItemAsWonDrop(multiplier.value - 1);
      },
      () => {
        isError.value = true;
      },
      isPendingCase,
    );
  };

  const getDrops = async (caseName: string, gameID: string): Promise<void> => {
    resetDropsWinner();
    await GlobalUtils.Api.handleRequest(
      async () => {
        const { data } = await $api.cases.getDrops(caseName, gameID);

        if (data.value) {
          userLinkId.value = data.value.data.userId;
          dropsWinner.value = data.value.data.items as IOpenItemBk[];
        }
      },
      (e: IError) => {
        alertStore.showError({
          title: e?.key || ErrorCodes.UNPREDICTED_EXCEPTION,
        });
      },
      isPending,
    );
  };

  const getSections = async (): Promise<void> => {};

  const getOpenProfit = (sum: number, price: number) => {
    const delta = sum - price;

    return {
      money: Math.floor(delta),
      percent: Math.floor((delta / price) * 100) + '%',
    };
  };
  const checkOpeningStatus = (error: IError) => {
    switch (error.key) {
      case ErrorCodes.CASE_UNAVAILABLE:
        return handleUnavailableCase();
      case ErrorCodes.BALANCE_ERROR:
        return (errorBalance.value = true);
      default:
        bkErrorStore.setBkModalErrorCode(error);
        bkModalStore.setCurrentComponent(ComponentNames.ERROR);
    }
  };

  const checkCaseStatus = async () => {
    const status = await $api.bk.checkCaseStatus(urlOptions.value.caseName, defaultUrl.value);
    if (!status.caseAvailability) handleUnavailableCase();
  };

  const openCase = async (isQuick: boolean) => {
    resetDropsWinner();
    errorBalance.value = false;
    return await GlobalUtils.Api.handleRequest(async () => {
      const data = urlOptions.value.isDemo
        ? await $api.bk.openCaseDemo(defaultUrl.value, urlOptions.value.caseName)
        : await $api.bk.openCase({ caseName: urlOptions.value.caseName, createAuthUrl });
      if (!data) {
        throw MessageError.DataNotFound;
      }

      function transformTo(data: object): IOpenItemsBk {
        // @ts-expect-error TODO remove or refactor
        const item = data?.items[0];
        // @ts-expect-error TODO remove or refactor
        const currency = data?.currency;
        return {
          items: [
            {
              dropItemData: {
                // @ts-expect-error TODO remove or refactor
                currentServerTime: data?.data?.currentServerTime,
                id: item.uniqId,
                imageData: {
                  image: item.image,
                },
                isUltraRare: !!item.rare,
                name: item.name,
                offerData: {
                  haveBounty: item.haveBounty,
                  countdown: item?.priceWithBountyEnd,
                  currency,
                  price: item?.priceWithBounty,
                },
                priceData: {
                  currency,
                  marketPrice: item.pricest,
                  price: item.price,
                },
                qualityEnum: {
                  name: item.quality,
                },
                takeAvailable: true,
                tastyCoins: null,
                type: item.itType,
                ...(item.csProperties && {
                  qualityShort: item.csProperties.qualityShort,
                  skin: item.csProperties.skin,
                  weapon: item.csProperties.weapon,
                }),
              },
              // @ts-expect-error TODO remove or refactor
              gameId: String(data?.data.casegame),

              inventoryId: item.id,
              taken: true,
            },
          ],
          tastyCoins: item.tastycoin,
          userId: item.userid,
        } as IOpenItemsBk;
      }

      const { items, tastyCoins } = transformTo(data);
      dropsWinner.value = items || [];
      addItemToNewInventory({
        id: dropsWinner.value[0]?.inventoryId,
        ...(dropsWinner.value[0]?.dropItemData.weapon && {
          qualityShort: dropsWinner.value[0].dropItemData.qualityShort,
          skin: dropsWinner.value[0].dropItemData.skin,
          weapon: dropsWinner.value[0].dropItemData.weapon,
        }),
        imageData: dropsWinner.value[0]?.dropItemData?.imageData,
        inactive: false,
        isOld: false,
        isUltraRare: dropsWinner.value[0]?.dropItemData?.isUltraRare,
        name: dropsWinner.value[0]?.dropItemData?.name,
        offerData: dropsWinner.value[0]?.dropItemData?.offerData,
        priceData: {
          currency: dropsWinner.value[0]?.dropItemData?.priceData?.currency,
          marketPrice: String(dropsWinner.value[0]?.dropItemData?.priceData?.marketPrice),
          price: String(dropsWinner.value[0]?.dropItemData?.priceData?.price),
        },
        qualityEnum: dropsWinner.value[0]?.dropItemData?.qualityEnum,
        taken: dropsWinner.value[0]?.taken,
        type: dropsWinner.value[0]?.dropItemData.type,
      });
      dropWinnerTastyCoins.value = tastyCoins;
      if (!items) {
        throw MessageError.DataNotFound;
      }
      // Крутим цикл дроплистов и в каждый подставляем нужный победный элемент
      for (let i = 0; i < listDropList.value.length; i++) {
        const item = items[i]?.dropItemData; // поставил 0 пока приходит только 1 предмет
        const itemList = listDropList.value[i];
        // @ts-expect-error TODO remove or refactor
        itemList[itemList.length - (idxFromTheRightBorderArrayToWinnerElement.value + 1)] = item;
      }

      // временно не работает для мультикаста
      const firstItemForAmplitude = items[0]?.dropItemData;

      const amplitudeData = getAmplitudeData();
      if (amplitudeData && itemCase.value) {
        const totalSum = dropList.value.reduce((acc, item) => {
          acc += +item.priceData.price;
          return acc;
        }, 0);
        const parsedPrice = +itemCase.value.priceData.price;
        const { money: rubProfit, percent: percentProfit } = getOpenProfit(totalSum, parsedPrice);

        BkEvents.bkCaseOpened({
          ...amplitudeData,
          'Fast Open': isQuick,
          'Item ID': firstItemForAmplitude.id,
          'Item Name': firstItemForAmplitude.name,
          'Item Price': +firstItemForAmplitude.priceData.price,
          'Item Rarity': firstItemForAmplitude.qualityEnum.name,
          'Open Mode': isDemo.value ? 'demo' : 'money',
          'User Profit in Percent': percentProfit,
          'User Profit in RUB': rubProfit,
        });
      }
      return true;
    }, checkOpeningStatus);
  };

  const getEventCases = async () => {
    await GlobalUtils.Api.handleRequest(
      async () => {
        const item: ICaseData[] | undefined = await $api.cases.getEventCases();
        eventCases.value = item || [];
      },
      (e: IError) => {
        alertStore.showError({
          title: e?.key || ErrorCodes.UNPREDICTED_EXCEPTION,
        });
      },
      isPending,
    );
  };

  const getMaxCasePriceForUser = async () => {
    await GlobalUtils.Api.handleRequest(
      async () => {
        maxCasePriceForUser.value = await $api.cases.getMaxCasePriceForUser();
      },
      (e: IError) => {
        alertStore.showError({
          title: e?.key || ErrorCodes.UNPREDICTED_EXCEPTION,
        });
      },
      isPending,
    );
  };

  const togglePending = () => (isPending.value = !isPending.value);

  const clearItemCase = () => {
    itemCase.value = {} as IBkItemCaseData;
  };

  const resetComputedListDropList = () => {
    listDropList.value = listDropList.value.map((itemList) => {
      const firstShowedItem = itemList.length - 10;
      const LastShowedItem = itemList.length - 1;
      // Оставляем предметы, которые видит пользователь, делаем их изначальными в новом массиве, шафлим другие предметы
      return [
        ...itemList.slice(firstShowedItem, LastShowedItem),
        ...GlobalUtils.Objects.shuffleArray(itemList.slice(0, firstShowedItem + 1)),
      ] as IBkCaseDropItem[];
    });
  };

  const resetComputedListDropListAfterChangeCase = () => {
    listDropList.value.shift();
  };
  const maxPriceCaseOkForUser = computed(() => maxCasePriceForUser.value);

  const getDropsWinnerTastyCoins = computed(() => dropWinnerTastyCoins.value || null);

  const sellAllItems = () => {};
  return {
    activeTypeDescriptionCase,
    allCases,
    allCasesSortsConcat,
    calculateStandEventAmplitude,
    carouselCount,
    caseBg,
    caseName,
    changeCounterCarousel,
    clearItemCase,
    computedListDropList: skipHydrate(computedListDropList),
    dropList,
    dropsWinner,
    error,
    errorBalance,
    eventCases,
    getCase,
    getDrops,
    getDropsWinnerTastyCoins,
    getEventCases,
    getMaxCasePriceForUser,
    getSections,
    idxFromTheRightBorderArrayToWinnerElement,
    isError,
    isPending,
    isPendingCase,
    itemCase,
    maxPriceCaseOkForUser,
    multiplier,
    openCase,
    resetComputedListDropList,
    resetComputedListDropListAfterChangeCase,
    resetDropsWinner,
    sellAllItems,
    setCaseName,
    sortedCases,
    togglePending,
    ultraCases,
    userLinkId,
    setCheapestItemAsWonDrop,
    checkCaseStatus,
  };
});
