<template>
  <SharedModal v-model="isChangeOpen" :disable-close-on-overlay-click="true">
    <BKIntegrationChangeItem
      v-if="itemsForReplacement.length && itemToReplacement"
      :item-replacement="itemsForReplacement"
      :loading="isPending"
      :case-item="itemToReplacement"
      :trade-list="tradeItems"
      :is-pending="isPendingReplace"
      @update-item="updateTradeItems"
      @sell-item="sellItem"
      @replace-item="replaceItem"
    />
    <div v-else>
      <BKIntegrationPopupError :title="t('alerts.error').toUpperCase()" :description="t('itemWithdrawalError')" />
    </div>
  </SharedModal>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { ref } from 'vue';
import type BkInventoryService from '../../services/BkInventoryService/BkInventoryService';
import { ComponentNames, KEY_INJECT_INVENTORY_SERVICE } from '../../constants/index.components';
import type { IEmitItemChangeController, IItemChangeControllerProps } from './ItemChangeController.types';
import { useBkModalStore } from '~/features/bk/store/bk.modal';
import BKIntegrationChangeItem from '~/features/bk/components/BKIntegrationChangeItem/BKIntegrationChangeItem.vue';
import BKIntegrationPopupError from '~/features/bk/components/BKIntegrationErrorPopup/BKIntegrationPopupError.vue';
import type { IBkCaseDropItem } from '~/repository/modules/bk/bkCases.types';
import { useBkInventoryStore } from '~/features/bk/store/bk.inventory';

const { t } = useI18n();

const inventoryStore = useBkInventoryStore();
const modalStore = useBkModalStore();

const props = withDefaults(defineProps<IItemChangeControllerProps>(), {
  modelValue: false,
});
const emit = defineEmits<IEmitItemChangeController>();

const { modelValue } = toRefs(props);
const { setCurrentComponent } = modalStore;

const { itemsForReplacement, itemToReplacement, tradeItems, isPending: isPendingReplace } = storeToRefs(inventoryStore);

const isChangeOpen = computed({
  get() {
    return modelValue.value;
  },
  set(value) {
    emit('update:modelValue', value);
  },
});

// Имитируем получение данных
const DELAY_PENDING = 3000;
const isPending = ref(true);
onMounted(() => {
  setTimeout(() => {
    isPending.value = !isPending.value;
  }, DELAY_PENDING);
});

function updateTradeItems(item: IBkCaseDropItem) {
  if (!tradeItems.value.length) {
    tradeItems.value.push(item);
    return;
  }
  const isItemIn = tradeItems.value.find((elem) => elem.id === item.id);
  if (isItemIn) tradeItems.value = tradeItems.value.filter((elem) => elem.id !== item.id);
  else tradeItems.value = [item];
}

const inventoryService = useInject<BkInventoryService>(KEY_INJECT_INVENTORY_SERVICE);

const sellItem = async () => {
  if (inventoryService) {
    if (!itemToReplacement.value) return;
    await inventoryService.sellWonItem(itemToReplacement.value);
    inventoryStore.resetItemToReplace();
    emit('update:modelValue', false);
  }
};

const replaceItem = async () => {
  const isSuccess = await inventoryStore.replaceItem();
  if (isSuccess) {
    emit('update:modelValue', false);
    setCurrentComponent(ComponentNames.MY_INVENTORY);
  }
};
</script>
