<template>
  <div class="pages">
    <button :disabled="isLessMinStep" class="pages__arrow" @click="changeSlide(VECTOR_STEP.left)">
      <SvgoSliderArrowIcon class="arrow-left arrow" />
    </button>
    <SharedText color="white" class="pages__counter">{{ `${counter}/${props.sliderLength}` }}</SharedText>
    <button :disabled="isMoreMaxStep" class="pages__arrow" @click="changeSlide(VECTOR_STEP.right)">
      <SvgoSliderArrowIcon class="pages__arrow-right arrow" />
    </button>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import type {
  IFrameSliderPagesEmit,
  IFrameSliderPagesProps,
  TVectorChangeSlide,
} from '~/features/bk/components/IFrameSliderPages/IFrameSliderPages.types';
import { VECTOR_STEP } from '~/features/bk/components/IFrameSliderPages/IFrameSliderPages.data';

const props = defineProps<IFrameSliderPagesProps>();
const emit = defineEmits<IFrameSliderPagesEmit>();
const counter = ref<number>(props.slideNumber);

const isMoreMaxStep = computed<boolean>(() => counter.value >= props.sliderLength);
const isLessMinStep = computed<boolean>(() => counter.value <= 1);

function changeSlide(type: TVectorChangeSlide) {
  const isLeft = type === VECTOR_STEP.left;
  const isRight = type === VECTOR_STEP.right;

  if (isLeft && !isLessMinStep.value) counter.value--;
  else if (isRight && !isMoreMaxStep.value) counter.value++;
  emit('update:slide-number', counter.value);
}

useWindowKeyboard('ArrowLeft', () => {
  changeSlide(VECTOR_STEP.left);
});
useWindowKeyboard('ArrowRight', () => {
  changeSlide(VECTOR_STEP.right);
});
</script>

<style src="./IFrameSliderPages.scss" scoped lang="scss"></style>
