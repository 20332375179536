<template>
  <div v-if="!blur" :style="varsCSS" :class="['background-drop-controller', props.type]">
    <slot />
  </div>

  <div v-else class="background-drop-controller">
    <img alt="bg" :src="caseBg?.image" :style="varsCSS" class="background-drop-image" />
    <slot />
  </div>
</template>

<script setup lang="ts">
import type { IBackgroundDropControllerProps } from '~/controllers/BackgroundDropController/backgroundDropController.types';

const props = defineProps<IBackgroundDropControllerProps>();
const { caseBg } = toRefs(props);
const varsCSS = ref({});

function setCssBg(bg: string): string {
  return `url(${bg})`;
}

function setFullBg(): void {
  if (typeof props.bg === 'string') {
    varsCSS.value = { '--bg': setCssBg(caseBg.value.image) };
  }
}

function setSideBg(): void {
  if (!caseBg.value.backgroundRight || !caseBg.value.backgroundLeft) return;
  varsCSS.value = {
    '--bg-left': setCssBg(caseBg.value.backgroundLeft),
    '--bg-right': setCssBg(caseBg.value.backgroundRight),
  };
}

function updateBackground() {
  if (!props.bg) return;

  switch (props.type) {
    case 'full':
      setFullBg();
      break;
    case 'side':
      setSideBg();
      break;
  }
}

watchEffect(() => {
  if (caseBg.value) {
    updateBackground();
  }
});
</script>

<style src="./BackgroundDropController.scss" lang="scss" scoped></style>
