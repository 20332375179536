<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="slider">
    <template v-for="slide in slideList" :key="slide.number">
      <div v-if="slide.number === slideNumber" class="slide">
        <!-- @vue-skip -->
        <SharedTransitionWrapper is="div" class="slide__image" transition-name="opacity" :ratio="2">
          <SharedImage :img="slide.image"></SharedImage>
        </SharedTransitionWrapper>
        <div class="slide__content">
          <!-- @vue-skip -->
          <SharedTransitionWrapper :transition-name="textTransition" :ratio="1.2">
            <SharedText v-bind="calculateTitleStyle" class="slide__title">{{ slide.title.text }} </SharedText>
          </SharedTransitionWrapper>
          <!-- @vue-skip -->
          <SharedTransitionWrapper :transition-name="textTransition">
            <SharedText v-bind="calculateDescriptionStyle" class="slide__description">
              <p v-html="slide.description.text"></p
            ></SharedText>
          </SharedTransitionWrapper>
          <!-- @vue-skip -->
          <SharedTransitionWrapper :transition-name="slideButtonsTransition" :ratio="1.2">
            <IFrameSliderPages
              v-model:slide-number="slideNumber"
              :slider-length="slideList.length"
              @update:buttons-transition="slideButtonsTransition"
              @update:text-transition="textTransition"
            />
          </SharedTransitionWrapper>
        </div>
      </div>
    </template>
  </div>
  <div v-if="showSliderEmitter" class="navigation_buttons">
    <slot name="navigation_buttons"></slot>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch } from 'vue';
import type { IFrameSliderEmit, IFrameSliderProps, TVectorTransition } from './IFrameSlider.types';
import { slidesBkInfo, VECTOR_TRANSITION } from './IFrameSlider.data';
import IFrameSliderPages from '~/features/bk/components/IFrameSliderPages/IFrameSliderPages.vue';
import { useAdaptivePreset } from '~/features/bk/composables/useAdaptivePreset';

const { t } = useI18n();

const slideList = slidesBkInfo(t);

const props = defineProps<IFrameSliderProps>();
const emit = defineEmits<IFrameSliderEmit>();
const textTransition = ref<TVectorTransition>(VECTOR_TRANSITION.bottom);
const slideButtonsTransition = ref<TVectorTransition>(VECTOR_TRANSITION.top);

const slideNumber = ref<number>(1);

const { calculateDescriptionStyle, calculateTitleStyle } = useAdaptivePreset();

const showSliderEmitter = computed<boolean>({
  get() {
    return props.showSlider;
  },
  set(value) {
    emit('update:show-slider', value);
  },
});

watch(
  () => slideNumber.value,
  (current, prev) => {
    textTransition.value = current > prev ? VECTOR_TRANSITION.bottom : VECTOR_TRANSITION.top;
    slideButtonsTransition.value = current > prev ? VECTOR_TRANSITION.top : VECTOR_TRANSITION.bottom;
  },
);
</script>

<style src="./iFrameSlider.scss" scoped lang="scss"></style>
