<template>
  <ul class="carusel-multiplier-group">
    <li v-for="item in count" :key="item" class="carusel-multiplier-group__item" @click="setActive(item)">
      <SharedButtonNew
        :color="xnButtonColorPreset"
        :form="xnButtonFormPresetPicker(item, count)"
        :state="{ active: isActive === item }"
        :text-props="xnButtonTextPreset"
      >
        {{ symbol }}
        {{ item * step }}
      </SharedButtonNew>
    </li>
  </ul>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import type { ICarouselMultiplierGroupEmits, ICarouselMultiplierGroupProps } from './BKCarouselMultiplierGroup.types';
import {
  xnButtonColorPreset,
  xnButtonFormPreset,
  xnButtonTextPreset,
  xnLeftButtonFormPreset,
  xnRightButtonFormPreset,
} from './BKCarouselMultiplierGroup.data';

const emit = defineEmits<ICarouselMultiplierGroupEmits>();
const props = withDefaults(defineProps<ICarouselMultiplierGroupProps>(), {
  count: 5,
  step: 1,
  symbol: 'x',
});

const isActive = ref<number>(props.modelValue);
function setActive(idx: number): void {
  isActive.value = idx;
  emit('update:modelValue', idx);
}

const xnButtonFormPresetPicker = computed(() => {
  return (buttonPosition: number, buttonsCount: number) => {
    // для самой левой кнопки, с borderRadius = '0 0 0 8px',
    if (buttonPosition === 1) return xnLeftButtonFormPreset;
    // для самой правой кнопки, с borderRadius =  '0 0 8px 0',
    if (buttonPosition === buttonsCount) return xnRightButtonFormPreset;
    // для остальных кнопок, с borderRadius = 0
    return xnButtonFormPreset;
  };
});
</script>

<style src="./BKCarouselMultiplierGroup.scss" scoped lang="scss" />
