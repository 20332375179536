import type { IItemSize } from '~/features/bk/components/BKIntegrationOldDropList/BKIntegrationOldDropList.types';

export const smallOldItemSize: IItemSize = {
  height: '84px',
  width: '104px',
};

export const mediumOldItemSize: IItemSize = {
  height: '112px',
  width: '142px',
};

export const smallNewItemSize: IItemSize = {
  height: '69px',
  width: '100%',
};

export const mediumNewItemSize: IItemSize = {
  height: '69px',
  width: '344px',
};
