import type { Component } from 'vue';
import IFrameCaseContent from '~/features/bk/components/IFrameCaseContent/IFrameCaseContent.vue';
import BKIntegrationChangeItemVue from '~/features/bk/components/BKIntegrationChangeItem/BKIntegrationChangeItem.vue';
import BKIntegrationPopupError from '~/features/bk/components/BKIntegrationErrorPopup/BKIntegrationPopupError.vue';
import BKIntegrationNotEnoughBalancePopup from '~/features/bk/components/BKIntegrationNotEnoughBalancePopup/BKIntegrationNotEnoughBalancePopup.vue';
import BKIntegrationMyInventoryPopup from '~/features/bk/components/BKIntegrationMyInventoryPopup/BKIntegrationMyInventoryPopup.vue';
import IFrameSlider from '~/features/bk/components/IFrameSlider/IFrameSlider.vue';
import BKIntegrationWonPopup from '~/features/bk/components/BKIntegrationWonPopup/BKIntegrationWonPopup.vue';

export const listComponent: Record<ComponentNames, Component> = {
  caseContent: IFrameCaseContent,
  denyExchange: BKIntegrationChangeItemVue,
  error: BKIntegrationPopupError,
  errorBalance: BKIntegrationNotEnoughBalancePopup,
  myInventory: BKIntegrationMyInventoryPopup,
  slider: IFrameSlider,
  won: BKIntegrationWonPopup,
} as const;

export enum ComponentNames {
  CASE_CONTENT = 'caseContent',
  DENY_EXCHANGE = 'denyExchange',
  ERROR = 'error',
  ERROR_BALANCE = 'errorBalance',
  MY_INVENTORY = 'myInventory',
  SLIDER = 'slider',
  WON = 'won',
}

export const KEY_INJECT_INVENTORY_SERVICE = 'inventoryService';
