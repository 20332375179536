import { storeToRefs } from 'pinia';
import type { TTypeCase } from '~/repository/modules/cases/cases.types';
import { useCaseStore } from '~/features/cases/store/useCases.store';

// Позволяет определить тип допустимый тип кейса
//  binding - массив доступных кейсов
export default function (binding: TTypeCase[]): boolean {
  const caseStore = useCaseStore();
  const { caseData } = storeToRefs(caseStore);
  if (!caseData.value?.type) return false;
  return binding.includes(caseData.value?.type);
}
