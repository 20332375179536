import { useAlertStore } from '~/store/alert/alert.store';
import type { EInventoryTakeStates, ITimerDataBk } from '~/repository/modules/bk/bk.types';

import type { TChangeItemStateArgs } from '~/store/inventory/types';

/** Отвечает за глобальное изменение состояние предмета
 * Решение под работу с инвентарём, основано на представлении  под features/bk, но без прямой привязанности
 * TODO композабл будет пересмотрен на момент реализации инвентаря в шапке
 * P.S сложность в реализации связана с непрямолинейным обновлением статусов предмета при вывода по веб-сокет
 * соединению
 * P.S.S замена предмета/вывод/обработка ошибок оставляет желать лучшего, поэтому привязывать напрямую к item.status
 * не стоит.
 * **/
export function useTakeItems() {
  const alertStore = useAlertStore();

  /** Метод изменения статуса предмета
   * Возможно вывести сообщение alert'ом при изменении статуса
   * Установить таймер в ожидаемое время
   * **/
  const changeItemState = ({
    alertText,
    alertType = 'success',
    takeItemStateSet,
    time,
    timeStamp,
  }: TChangeItemStateArgs) => {
    if (alertText) {
      alertStore.show({
        title: alertText,
        type: alertType,
      });
    }
    const current = Date.now();
    /** Состояние предмета - аналог статуса с бэка **/
    const takeItemState: EInventoryTakeStates | null = takeItemStateSet !== undefined ? takeItemStateSet : null;
    /** Объект конфигурации таймера
     * timerData имеет временную привязку к таймеру на БК, т.к инвентарь реализован только на БК
     * timeToExpire - дата/время окончания таймера(timestamp)
     * timerSetTimeStamp - дата/время установки таймера(timestamp)
     * **/
    const timerData: ITimerDataBk | null =
      time === null ? null : { timeToExpire: current + (time || 0), timerSetTimeStamp: timeStamp || current };

    return { takeItemState, timerData };
  };

  return {
    changeItemState,
  };
}
