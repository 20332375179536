<template>
  <div class="demo-block" :style="style">
    <SharedText class="demo-block-title" :size="titleTextSize" :color="titleTextColor" :weight="FontWeights.MEDIUM">
      {{ title }}
    </SharedText>
    <SharedText
      class="demo-block-balance"
      :size="textSize"
      :letter-spacing="'1px'"
      :color="balanceTextColor"
      :weight="FontWeights.BLACK"
    >
      {{ useFormatCurrency(priceToShow, currency) }}
    </SharedText>
  </div>
</template>
<script setup lang="ts">
import type { CSSProperties } from 'vue';
import { computed, toRefs } from 'vue';
import { FontSizes, FontWeights } from '~/types/SharedFont.types';
import { Colors } from '~/constants/colors.constants';
import { Breakpoints } from '~/constants/media.constants';
import type { IBKIntegrationDemoBlockProps } from '~/features/bk/components/BKIntegrationDemoBlock/BKIntegrationDemoBlock.types';
import { useDemoStore } from '~/features/bk/store/demo';

const props = withDefaults(defineProps<Partial<IBKIntegrationDemoBlockProps>>(), {
  background: 'var(--main-color-400)',
  balanceText: '10000',
  balanceTextColor: Colors.BK_INTEGRATION.BALANCE_GREEN,
  titleText: '',
  titleTextColor: Colors.NEUTRAL.WHITE,
});
const { t } = useI18n();
const demoStore = useDemoStore();
const { currency } = storeToRefs(demoStore);
const { background } = toRefs(props);

const viewport = useViewport();

const title = props.titleText ? props.titleText : t('demoBalance');

const titleTextSize = computed(() => {
  return viewport.isGreaterOrEquals(Breakpoints.md) ? FontSizes.LARGE : FontSizes.SMALL;
});

const textSize = computed(() => {
  return viewport.isGreaterOrEquals(Breakpoints.md) ? FontSizes.LARGE_XL : FontSizes.MEDIUM;
});

const style = computed<CSSProperties>(() => {
  const properties: CSSProperties = {};

  properties.backgroundColor = GlobalUtils.CSS.parseColor(background.value).backgroundColor;

  return properties;
});

const priceToShow = computed(() => {
  return Math.floor(+demoStore.balance);
});
</script>
<style scoped src="./BKIntegrationDemoBlock.scss"></style>
