import { Colors } from '~/constants/colors.constants';
import { FontSizes } from '~/types/SharedFont.types';
import type { ISharedButtonProps } from '~/components/SharedButton/SharedButton.types';
import { BorderRadius } from '~/constants/common.constants';
import type { TBackgroundStyle } from '~/types/SharedButton.types';

const styleButton = (
  height: string,
  fontSize: string,
  color: TBackgroundStyle,
  colorText: string,
  borderRadius?: string,
): Partial<ISharedButtonProps> => {
  return {
    backgroundStyle: color,
    border: {
      borderColor: 'transparent',
      borderRadius: borderRadius || BorderRadius.XS3,
      borderStyle: 'none',
      borderWidth: '0',
    },
    height,
    textProps: {
      color: colorText,
      size: fontSize,
    },
  };
};

// Пресеты с учётом общей цветовой стилистики
const presetThemeMain: IPresetButtonBkIntegrationWonPopup = {
  color: 'green',
  colorText: Colors.NEUTRAL.BLACK,
};

const presetThemeAction: IPresetButtonBkIntegrationWonPopup = {
  color: 'purple',
  colorText: Colors.NEUTRAL.WHITE,
};

interface IPresetButtonBkIntegrationWonPopup {
  color: TBackgroundStyle;
  colorText: string;
}

// Пресеты с учётом адаптива
const presetDesktop = ({ color, colorText }: IPresetButtonBkIntegrationWonPopup, isAction: boolean) => {
  return styleButton('50px', FontSizes.LARGE, color, colorText, isAction ? BORDER_FOR_ACTION : BORDER_FOR_MAIN);
};

const BORDER_FOR_MAIN = '4px 4px 0 0';
const BORDER_FOR_ACTION = '0 0 4px 4px';

const presetTablet = (style: IPresetButtonBkIntegrationWonPopup, isAction: boolean) => {
  return {
    ...presetMobile(style, isAction ? BORDER_FOR_ACTION : BORDER_FOR_MAIN),
  };
};

const presetMobile = ({ color, colorText }: IPresetButtonBkIntegrationWonPopup, border?: string) => {
  return styleButton('40px', FontSizes.MEDIUM, color, colorText, border);
};

// Набор стилей
export const styleMainButtonDesktop = {
  ...presetDesktop(presetThemeMain, false),
};
export const styleMainButtonMobile = {
  ...presetMobile(presetThemeMain),
};

export const styleMainButtonTablet = {
  ...presetTablet(presetThemeMain, false),
};

export const styleActionButtonsDesktop = {
  ...presetDesktop(presetThemeAction, false),
};
export const styleActionButtonsMobile = {
  ...presetMobile(presetThemeAction),
};

export const styleActionButtonsTablet = {
  ...presetTablet(presetThemeAction, true),
};
