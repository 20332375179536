import type { ComposerTranslation } from 'vue-i18n';
import type { useVolumeStore } from '~/store/volume/volume';
import type { useBkModalStore } from '~/features/bk/store/bk.modal';
import type { IBkActionButton } from '~/features/bk/components/BKIntegrationActionButtons/BkIntegrationActionButtons.types';
import { SvgoVolumeOffIcon, SvgoVolumeOnIcon } from '#components';
import { ComponentNames } from '~/features/bk/constants/index.components';

// Кнопки для меню
export const actionButtons = (
  volumeStore: ReturnType<typeof useVolumeStore>,
  bkModalStore: ReturnType<typeof useBkModalStore>,
  t: ComposerTranslation,
): IBkActionButton[] => {
  return [
    {
      action: () => volumeStore.toggleMute(),
      active: !volumeStore.isMuteCookie,
      icon: SvgoVolumeOnIcon,
      text: t('soundOn'),
    },
    {
      action: () => volumeStore.toggleMute(),
      active: volumeStore.isMuteCookie,
      icon: SvgoVolumeOffIcon,
      text: t('soundOff'),
    },
    {
      action: () => bkModalStore.setCurrentComponent(ComponentNames.SLIDER),
      active: true,
      icon: 'svgoInfoIcon',
      text: t('gameInformation'),
    },
    {
      action: () => bkModalStore.setCurrentComponent(ComponentNames.CASE_CONTENT),
      active: true,
      icon: 'svgoBkIntegrationCaseContentIcon',
      text: t('caseContent'),
    },
    {
      action: () => bkModalStore.setCurrentComponent(ComponentNames.MY_INVENTORY),
      active: true,
      icon: 'svgoMyInventoryIcon',
      text: t('myInventory'),
    },
  ].filter((actionButton) => actionButton.active);
};
