import { computed, ref } from 'vue';
import { ErrorCodes } from '~/api/global/errors/codes/codes';
import type { IError } from '~/repository/extensions/error/error.types';

const { snake2Camel } = GlobalUtils.Text;

export const useBkErrorStore = defineStore('bk/error', () => {
  const errorCodeInt = ref<string>('');
  const errorTitle = ref<string>('');

  // getters

  const errorCode = computed(() => errorCodeInt.value);
  const title = computed(() => errorTitle.value);

  // methods

  const setBkModalErrorCode = (e: IError | Error) => {
    if (e instanceof Error) {
      // если общая ошибка
      errorCodeInt.value = `${snake2Camel(ErrorCodes.UNPREDICTED_EXCEPTION)}`;
    } else {
      e?.key && Object.values(ErrorCodes).includes(e.key)
        ? (errorCodeInt.value = `${snake2Camel(e.key)}`)
        : (errorCodeInt.value = `${snake2Camel(ErrorCodes.UNPREDICTED_EXCEPTION)}`);
    }
  };

  return {
    errorCode,
    setBkModalErrorCode,
    title,
  };
});
