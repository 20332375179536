import type {
  ISharedButtonNewColorPreset,
  ISharedButtonNewFormPreset,
  TSharedButtonTextPreset,
} from '~/types/SharedButtonNew.types';
import { Colors } from '~/constants/colors.constants';
import { defaultMiddleButtonFormPreset } from '~/components/SharedButtonNew/SharedButtonNew.data';

export const xnButtonColorPreset: ISharedButtonNewColorPreset = {
  color: 'var(--main-color-200)',
  colorBorder: 'var(--main-color-200)',
  colorHovered: 'var(--main-color-300)',
  colorInactive: 'var(--main-color-400)',
};

export const xnButtonFormPreset: ISharedButtonNewFormPreset = {
  ...defaultMiddleButtonFormPreset,
  height: '50px',
  media: {
    md: { height: '40px', minWidth: '48px', width: '48px' },
    sm: { height: '40px', minWidth: '48px', width: '48px' },
  },
  minWidth: '50px',
  radius: 0,
  width: '50px',
};

export const xnLeftButtonFormPreset: ISharedButtonNewFormPreset = {
  ...xnButtonFormPreset,
  radius: '0 0 0 8px',
};

export const xnRightButtonFormPreset: ISharedButtonNewFormPreset = {
  ...xnButtonFormPreset,
  radius: '0 0 8px 0',
};

export const xnButtonTextPreset: TSharedButtonTextPreset = {
  color: Colors.NEUTRAL.WHITE,
  letterSpacing: '-1px',
};
