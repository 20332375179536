// Определяем на странице какой игры мы находимся
export function useBkGame() {
  const config = useRuntimeConfig();

  const isCsGame = config.public.tastyProject === 'cs';
  const isDotaGame = config.public.tastyProject === 'dota';

  return {
    isCsGame,
    isDotaGame,
  };
}
