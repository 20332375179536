// По сокетам приходят корявые объекту приводим их нужному типу
import type { IItemWsBk } from '~/features/bk/types/inventoryItems.types';
import type { IBkCaseDropItem } from '~/repository/modules/bk/bkCases.types';
import type { TCurrencyType } from '~/types/Shared.types';
import type { IUrlOptions } from '~/features/bk/types/integrator.types';

export function webSocketDatatransformTo(items: IItemWsBk[], options: IUrlOptions): IBkCaseDropItem[] {
  const arr: IBkCaseDropItem[] = [];
  for (let i = 0; i < items.length; i++) {
    const item = items[i];
    // Здесь пересчёт на другую валюту
    arr.push({
      id: item.id,
      imageData: {
        image: item.img,
      },
      isUltraRare: false,
      name: item.name,
      offerData: {
        countdown: 0,
        currency: options.currencyIso as TCurrencyType,
        price: item.price,
      },
      priceData: {
        currency: options.currencyIso as TCurrencyType,
        marketPrice: item.oldPrice,
        price: item.price,
      },
      qualityEnum: {
        name: item.quality,
      },
    });
  }
  return arr;
}
