<template>
  <div class="case-container">
    <!-- @vue-skip -->
    <CaseInfoWidget
      :is-more-breakpoint-xl="xl"
      :item-case="itemCase"
      bg="var(--main-color-600)"
      :active-type-case="activeTypeDescriptionCase"
      :is-coins-enabled="false"
    />
    <BKCaseCarouselWidget
      v-if="rerenderRoulette"
      v-model:multiplier="multiplier"
      :buttons-colors-preset="{ open: BKOpenCaseBtnColorPreset, openFast: BKOpenCaseFastBtnColorPreset }"
      :color-fill="Colors.NEUTRAL.BLACK_75"
      :is-auth="true"
      :balance="demoStore.isDemo ? demoStore.balance.toString() : '0'"
      :is-pending="isPending"
      :is-available="true"
      :multi-price-data="multiPriceData"
      :carousel-count="carouselCount"
      :item-case="itemCase"
      :item-top-price-options="demoStore.isDemo ? topPriceBKDemoPreset : topPriceBKPreset"
      :price-options="{ color: Colors.BK_INTEGRATION.BALANCE_GREEN, fontWeight: FontWeights.BOLD }"
      :item-end-count="idxFromTheRightBorderArrayToWinnerElement"
      :list-drop-list="computedListDropList"
      :custom-currency="demoStore.isDemo ? demoStore.currency : ''"
      :max-price-case-ok-for-user="maxPriceCaseOkForUser"
      @open="openCase"
      @to-drop-page="showWonPopup"
      @on-next-item="volumeNextItem"
      @handler-sell="bkModalStore.setCurrentComponent(ComponentNames.CASE_CONTENT)"
    />
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useLocalIFrameStore } from '../../store/localIFrame';
import {
  BKOpenCaseBtnColorPreset,
  BKOpenCaseFastBtnColorPreset,
  topPriceBKDemoPreset,
  topPriceBKPreset,
} from '../../components/BKCaseCarouselWidget/BKCaseCarouselWidget.data';
import { Colors } from '~/constants/colors.constants';
import { volumesKeys } from '~/store/volume/volumes.data';
import { useVolumeStore } from '~/store/volume/volume';
import type { IOpenItem } from '~/repository/modules/cases/cases.types';
import { useDemoStore } from '~/features/bk/store/demo';
import { FontWeights } from '~/types/SharedFont.types';
import { useBkCasesStore } from '~/features/bk/store/bk.cases';
import { useBkModalStore } from '~/features/bk/store/bk.modal';
import { ComponentNames } from '~/features/bk/constants/index.components';
import BKCaseCarouselWidget from '~/features/bk/components/BKCaseCarouselWidget/BKCaseCarouselWidget.vue';
import CaseInfoWidget from '~/features/cases/widgets/CaseInfoWidgetLegacy/CaseInfoWidget.vue';

const { xl } = useMedia();

const store = useBkCasesStore();
const volumeStore = useVolumeStore();
const demoStore = useDemoStore();
const bkModalStore = useBkModalStore();
const localIFrameStore = useLocalIFrameStore();

const {
  itemCase,
  activeTypeDescriptionCase,
  multiplier,
  errorBalance,
  isPending,
  carouselCount,
  idxFromTheRightBorderArrayToWinnerElement,
  computedListDropList,
  maxPriceCaseOkForUser,
} = storeToRefs(store);

const { changeAnimation } = localIFrameStore;

// Открытие кейса выполнено успешно или нет
const isNextRedirect = ref<boolean>();

// Цена за кол-во кейсов
const multiPriceData = computed<number>(() => {
  if (!itemCase.value || !itemCase.value.priceData || !itemCase.value.priceData.price) return 0;
  return +itemCase.value.priceData.price * multiplier.value;
});

onMounted(() => {
  volumeStore.loadVolume(volumesKeys.caseOpen);
  volumeStore.loadVolume(volumesKeys.caseScroll);
  volumeStore.loadVolume(volumesKeys.caseReveal);
});

let startCarousel = false;

const createGameIdString = (arr: IOpenItem[] | undefined): string => {
  if (arr) return arr.reduce((acc: string, value) => acc + (acc === '' ? '' : '&') + value?.gameId, '');
  return '';
};

const rerenderRoulette = ref(true);
const showWonPopup = () => {
  changeAnimation(false);
  // пересчитываем предметы в рулетке с целью перерендера
  store.resetComputedListDropList();
  store.setCheapestItemAsWonDrop(0);

  rerenderRoulette.value = !rerenderRoulette.value;
  nextTick(() => {
    rerenderRoulette.value = !rerenderRoulette.value;
  });
  store.togglePending();
  if (demoStore.isDemo && itemCase.value) {
    demoStore.reduceBalance(itemCase.value.priceData.price);
    demoStore.addToInventory(store?.dropsWinner[0]?.dropItemData);
  }
  const gameId = createGameIdString(store?.dropsWinner);
  if (!gameId) return;
  volumeStore.playVolume(volumesKeys.caseReveal);

  bkModalStore.handlerClose(ComponentNames.WON);
  startCarousel = false;
};

const openCase = async (isQuick: boolean, startAnimation: (carouselCount: number) => void) => {
  await store.checkCaseStatus();
  if (!startAnimation || startCarousel) return;
  startCarousel = true;
  if (demoStore.isDemo && +demoStore.balance <= +store.itemCase?.priceData.price) {
    startCarousel = false;
    return bkModalStore.setCurrentComponent(ComponentNames.ERROR_BALANCE);
  }
  store.togglePending();
  store.changeCounterCarousel();
  volumeStore.playVolume(volumesKeys.caseOpen);
  if (!store?.itemCase) return;
  if (!isQuick) {
    startAnimation(carouselCount.value);
    changeAnimation(true);
  }
  isNextRedirect.value = (await store.openCase(isQuick)) ?? undefined;
  if (isQuick && isNextRedirect.value) showWonPopup();
  if (isQuick && !isNextRedirect.value) store.togglePending();
  if (!demoStore.isDemo) startCarousel = false;
};

watch(errorBalance, () => {
  if (errorBalance.value) {
    bkModalStore.setCurrentComponent(ComponentNames.ERROR_BALANCE);
    errorBalance.value = false;
    startCarousel = false;
  }
});

const volumeNextItem = (isQuick: boolean) => {
  if (!isQuick) {
    volumeStore.playVolume(volumesKeys.caseScroll);
  }
};

onUnmounted(() => store.calculateStandEventAmplitude());
</script>
<style scoped src="./IFrameCaseOpenController.scss"></style>
