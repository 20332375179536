import {
  descriptionMobileStyle,
  descriptionStyle,
  titleStyle,
  titleStyleDesktop,
} from '~/features/bk/components/IFrameSlider/IFrameSlider.data';
import { Breakpoints } from '~/constants/media.constants';

// Функция хелпер
// Вычисляет пресеты для popup в зависимости от размера экрана
export function useAdaptivePreset() {
  const viewport = useViewport();

  const isMoreXl = computed(() => viewport.isGreaterOrEquals(Breakpoints.xl));
  const calculateDescriptionStyle = computed(() => (isMoreXl.value ? descriptionStyle : descriptionMobileStyle));
  const calculateTitleStyle = computed(() => (isMoreXl.value ? titleStyleDesktop : titleStyle));

  return {
    calculateDescriptionStyle,
    calculateTitleStyle,
  };
}
