import {
  EBkEvents,
  type IBkCaseEnteredEvent,
  type IBkCaseLeaveEvent,
  type TBkCaseOpenedEvent,
} from '../types/bk.types';

export class BkEvents {
  static bkCaseEntered(event: IBkCaseEnteredEvent) {
    return useAmplitude().sendEvent('BETTING', {
      event: EBkEvents.BK_CASE_ENTERED,
      eventProperties: event,
    });
  }

  static bkCaseLeave(event: IBkCaseLeaveEvent) {
    return useAmplitude().sendEvent('BETTING', {
      event: EBkEvents.BK_CASE_LEAVE,
      eventProperties: event,
    });
  }

  static bkCaseOpened(event: TBkCaseOpenedEvent) {
    return useAmplitude().sendEvent('BETTING', {
      event: EBkEvents.BK_CASE_OPENED,
      eventProperties: event,
    });
  }
}
