<template>
  <div :style="varsCSS" class="loader-wrapper">
    <div class="loader"></div>
    <component :is="loaderIcon" class="loader__icon" />
  </div>
</template>

<script setup lang="ts">
import type { IPropsLoader } from './BKIntegrationInventoryLoader.types';

const { toPx } = GlobalUtils.Converting;

const props = withDefaults(defineProps<IPropsLoader>(), {
  height: 64,
  heightIcon: 28,
  loaderIcon: 'SvgoCaseLoaderIcon',
  minHeight: 48,
  minWidth: 48,
  width: 64,
  widthIcon: 32,
});

const { height, heightIcon, minHeight, minWidth, width, widthIcon } = toRefs(props);

const varsCSS = computed(() => ({
  '--height': toPx(height.value),
  '--height-icon': toPx(heightIcon.value),
  '--min-height': toPx(minHeight.value),
  '--min-width': toPx(minWidth.value),
  '--width': toPx(width.value),
  '--width-icon': toPx(widthIcon.value),
}));
</script>

<style lang="scss" src="./BKIntegrationInventoryLoader.scss" scoped></style>
