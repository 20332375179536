export const carouselTiming: KeyframeAnimationOptions = {
  delay: 100,
  direction: 'normal',
  duration: 12000,
  easing: 'cubic-bezier(0.075, 0.9, 0.4, 1)',
  fill: 'both',
  iterations: 1,
};

export const multiCarouselTiming: KeyframeAnimationOptions = {
  direction: 'normal',
  duration: 350,
  easing: 'cubic-bezier(0.25, 0.1, 0.25, 1)',
  fill: 'both',
  iterations: 1,
};
