import type { TBKIntegrationItemTimers } from './BKIntegrationItemComponent.types';
import type {
  ISharedButtonNewColorPreset,
  ISharedButtonNewFormPreset,
  TSharedButtonTextPreset,
} from '~/types/SharedButtonNew.types';
import {
  defaultButtonColorPreset,
  defaultMiddleButtonFormPreset,
} from '~/components/SharedButtonNew/SharedButtonNew.data';
import { TIMES } from '~/constants/times.constants';

export const itemSmallButtonFormPreset: ISharedButtonNewFormPreset = {
  ...defaultMiddleButtonFormPreset,
  height: '20px',
  margin: '0 var(--gap-4xs) 0 0',
  minWidth: '88px',
  padding: '0',
  radius: 'var(--border-radius-4xs)',
  width: '88px',
};

export const itemSellButtonColorPreset: ISharedButtonNewColorPreset = {
  ...defaultButtonColorPreset,
  color: 'var(--bk-integration-green)',
  colorHovered: 'var(--bk-integration-green)',
};

export const itemGetButtonColorPreset: ISharedButtonNewColorPreset = {
  ...defaultButtonColorPreset,
  color: 'var(--bk-integration-purple)',
  colorHovered: 'var(--bk-integration-purple)',
};

export const itemSellButtonTextPreset: TSharedButtonTextPreset = {
  color: 'var(--neutral-black)',
  size: '8px',
  weight: '800',
};

export const itemGetButtonTextPreset: TSharedButtonTextPreset = {
  color: 'var(--neutral-white)',
  size: '8px',
  weight: '800',
};

export const itemBigSellButtonFormPreset: ISharedButtonNewFormPreset = {
  ...defaultMiddleButtonFormPreset,
  height: '35px',
  margin: '0 0 var(--gap-4xs) 0',
  minWidth: '100%',
  radius: 'var(--border-radius-4xs) 0 0 var(--border-radius-4xs)',
  width: '100%',
};

export const itemBigGetButtonFormPreset: ISharedButtonNewFormPreset = {
  ...defaultMiddleButtonFormPreset,
  height: '23px',
  minWidth: '100%',
  radius: 'var(--border-radius-4xs) 0 0 var(--border-radius-4xs)',
  width: '100%',
};

export const itemBigSellButtonColorPreset: ISharedButtonNewColorPreset = {
  ...defaultButtonColorPreset,
  color: 'var(--bk-integration-green)',
  colorHovered: 'var(--bk-integration-green)',
};

export const itemBigGetButtonColorPreset: ISharedButtonNewColorPreset = {
  ...defaultButtonColorPreset,
  color: 'var(--bk-integration-purple)',
  colorHovered: 'var(--bk-integration-purple)',
};

export const itemBigSellButtonTextPreset: TSharedButtonTextPreset = {
  color: 'var(--neutral-black)',
  size: '16px',
  weight: 900,
};
export const itemBigSellButtonHoverTextPreset: TSharedButtonTextPreset = {
  color: 'var(--neutral-black)',
  size: '10px',
  weight: 900,
};

// значение таймеров (в минутах)

export const takeItemStateTimers: TBKIntegrationItemTimers = {
  awaitSellerTimer: 5 * TIMES.MINUTE,
  prepareItemTimer: 5 * TIMES.MINUTE,
  takeItemTimer: 10 * TIMES.MINUTE,
  takeItemTimerLong: TIMES.HOUR,
};
