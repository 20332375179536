<template>
  <div :class="rootClasses">
    <div class="caseDropList__data">
      <div v-if="extraItems.length" class="caseDropList__ultras">
        <transition name="ultras-title">
          <SharedTitle
            v-if="disableAnimation || isMounted"
            class="ultras__title"
            as="h4"
            hide-side-lines
            :text-props="{
              textShadow: '0px 0px 20px #00C487',
              color: '#00C487',
              align: 'center',
              size: 'inherit',
            }"
          >
            {{ t('ultrarare') }}
          </SharedTitle>
        </transition>
        <div class="ultras_list_container">
          <transition name="ultras-back">
            <div v-if="disableAnimation || isMounted" class="container__back">
              <div class="container__back_line"></div>
              <div class="container__back_band"></div>
            </div>
          </transition>
          <div class="container__list">
            <transition-group name="ultras-item">
              <SharedDropItem
                v-for="ultraItem in extraItems"
                v-show="isMounted"
                :key="ultraItem.id"
                :custom-currency="customCurrency"
                :top-line="{
                  color: topLineColor,
                }"
                :bottom-line="{
                  textProps: {
                    size: textSize,
                  },
                }"
                :image="{
                  png1: ultraItem.imageData.image,
                }"
                :is-transparent-title="!!(ultraItem as TCaseDropItem).weapon"
                :main-color="Colors.RARITY.ULTRA"
                :item-price="{ ...ultraItem?.priceData, price: String(ultraItem?.priceData?.marketPrice || 0) }"
                :item-name="(ultraItem as TCaseDropItem).weapon || ultraItem.name"
                :item-skin="(ultraItem as TCaseDropItem).skin"
                preview-only
                :quality="{
                  qualityFull: ultraItem?.qualityFull,
                  qualityShort: ultraItem?.qualityShort,
                }"
                :style="{
                  fontWeight: 'var(--black)',
                  color: 'var(--neutral-white)',
                  fontSize: 'var(--font-size-s)',
                }"
                @click="createSteamMarketLink(SteamMarketIds.DOTA, ultraItem.name)"
              />
            </transition-group>
          </div>
        </div>
      </div>
      <transition name="content" :css="!authorized">
        <div v-if="!authorized ? disableAnimation || isMounted : true" class="caseDropList__content">
          <div v-if="!authorized" class="content__lock">
            <slot name="lock">
              <component :is="parsedLockInfo.icon" class="content__lock_icon" />
              <SharedText
                class="content__lock_info"
                align="center"
                :weight="FontWeights.BOLD"
                :color="Colors.NEUTRAL.WHITE"
                wrap="break-spaces"
                v-bind="parsedLockInfo.textProps"
              >
                {{ parsedLockInfo.text }}
              </SharedText>
            </slot>
          </div>
          <div
            ref="contentList"
            :class="['content__list', isMaxWidth && 'max-width']"
            :style="!authorized ? LIST_BLUR_CSS : ''"
          >
            <transition-group name="list-item" :css="authorized">
              <SharedDropItem
                v-for="(item, index) in props.items"
                v-show="isMounted"
                :key="index"
                :disable-animation="!authorized"
                :top-line="{
                  color: topLineColor,
                }"
                :bottom-line="{
                  textProps: {
                    size: textSize,
                  },
                }"
                :is-transparent-title="!!(item as TCaseDropItem).weapon"
                :item-name="(item as TCaseDropItem).weapon || item.name"
                :item-type="item.type"
                :item-skin="(item as TCaseDropItem).skin"
                :custom-currency="customCurrency"
                :image="{
                  png1: item.imageData.image,
                }"
                :item-price="{ ...item.priceData, price: String(item?.priceData?.marketPrice || 0) }"
                :main-color="GlobalUtils.Colors.getColorsRarity(item?.qualityEnum?.name)"
                preview-only
                disable-shadow
                size="medium"
                :quality="{
                  qualityFull: item?.qualityFull,
                  qualityShort: item?.qualityShort,
                }"
                :style="{
                  fontWeight: 'var(--black)',
                  color: 'var(--neutral-white)',
                  fontSize: 'var(--font-size-s)',
                }"
                @click="toSteamLink(item.name)"
              />
            </transition-group>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, toRefs } from 'vue';
import type { ICaseDropListProps } from './BkCaseDropList.types';
import { Colors } from '~/constants/colors.constants';
import { createSteamMarketLink, SteamMarketIds } from '~/utils/steam.utils';
import { FontWeights } from '~/types/SharedFont.types';
import type { TCaseDropItem } from '~/features/cases/types/case.types';
import { Breakpoints } from '~/constants/media.constants';

const { t } = useI18n();

const COMPONENT_NAME = 'caseDropList';

const LIST_BLUR_CSS = 'filter: blur(12px) !important';

const props = withDefaults(defineProps<ICaseDropListProps>(), {
  authorized: false,
  disableAnimation: false,
  extraItems: () => [],
  items: () => [],
});

const parsedLockInfo = computed(() => {
  if (!props.lockInfo) {
    return {
      icon: 'SvgoLockIcon',
      text: t('lostLocales.bkNeedToAuth'),
      textProps: {},
      topLineColor: 'var(--additional-red-semi-dark)',
    };
  }

  return props.lockInfo;
});

const { disableAnimation } = toRefs(props);

const isMounted = ref(false);
const viewport = useViewport();

const contentList = ref<HTMLElement>();
let observer: MutationObserver;

const rootClasses = computed(() => ({
  [`${COMPONENT_NAME}`]: true,
  [`${COMPONENT_NAME}--unauthorized`]: !props.authorized,
}));

const textSize = computed(() => {
  return viewport.isGreaterOrEquals(Breakpoints.md) ? 'var(--font-size-m)' : 'var(--font-size-xs)';
});

// cancel an ability to disable blurring of the items list via dev tools
watchEffect(() => {
  nextTick(() => {
    if ((contentList.value as HTMLElement) && !props.authorized) {
      observer = new MutationObserver((records) => {
        const change = records.find((record) => record.attributeName === 'style');
        if (change && !(change.target as HTMLElement).style.filter.includes('blur')) {
          (contentList.value as HTMLElement).setAttribute('style', LIST_BLUR_CSS);
        }
      });

      observer.observe(contentList.value as HTMLElement, {
        attributes: true,
      });
    } else if (observer && props.authorized) {
      observer.disconnect();
    }
  });
});

const toSteamLink = (name: string) => {
  if (!props.authorized) return;
  createSteamMarketLink(SteamMarketIds.DOTA, name);
};

onMounted(() => {
  isMounted.value = true;
});

onUnmounted(() => {
  if (observer) {
    observer.disconnect();
  }
});
</script>

<style src="./BkCaseDropList.scss" scoped lang="scss"></style>
