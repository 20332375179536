<template>
  <div v-if="auth" class="carusel-button-navigation">
    <ClientOnly>
      <Transition mode="out-in" name="fade">
        <div v-if="loading && isMounted">
          <SharedButtonNew
            :form="loadingBtnFormPicker"
            :color="loaderBtnColorPreset"
            :text-props="loadingBtnTextSizePicker"
            :state="{ disabled: true }"
            class="carusel-button-navigation__loading"
            ><span class="carusel-button-navigation__loader-text">
              {{ defaultText }}
            </span>
            <slot name="loader" />
          </SharedButtonNew>
        </div>

        <div v-else class="carusel-button-navigation__inner">
          <div
            :class="{ 'carusel-button-navigation__prepend-bg': backgroundPrepend }"
            :style="(backgroundPrepend && styles) || {}"
          >
            <div v-if="prepend" class="carusel-button-navigation__prepend">
              <slot name="prepend" />
            </div>
            <div class="carusel-button-navigation__item-list" :class="[available ? 'available' : 'non-available']">
              <slot
                v-if="available"
                name="available"
                :default-price-options="defaultPriceOptions"
                :default-buttons="defaultButtons"
              />
              <slot
                v-else
                name="unavailable"
                :default-price-options="defaultPriceOptions"
                :default-buttons="defaultButtons"
              />
            </div>
          </div>

          <div v-if="!available" class="carusel-button-navigation__item">
            <SharedButton
              v-bind="bindPropsButtonAdded"
              class="carusel-button-navigation__item--to"
              @click="handlerSell"
            >
              <slot name="description-selection-button" />
            </SharedButton>
          </div>

          <slot name="selected-text" />
        </div>
      </Transition>
    </ClientOnly>
  </div>
  <div v-if="!auth" class="carusel-button-navigation__unauth" :style="styles">
    <slot name="auth-icons" />
  </div>
</template>

<script setup lang="ts">
import type { CSSProperties } from 'vue';
import { computed } from 'vue';
import {
  defaultButtonAdded,
  defaultButtons,
  defaultPriceOptions,
  loaderBtnBigTextPreset,
  loaderBtnColorPreset,
  loaderBtnLargeFormPreset,
  loaderBtnMediumFormPreset,
  loaderBtnSmallFormPreset,
  loaderBtnSmallTextPreset,
} from './BKCarouselButtonNavigationGroup.data';
import type {
  TBKButtonCarouselNavigation,
  IBKButtonNavigationGroup,
  IPropsBKCarouselButtonNavigationGroup,
} from './BKCarouselButtonNavigationGroup.types';
import SharedButtonNew from '~/components/SharedButtonNew/SharedButtonNew.vue';
import { Breakpoints } from '~/constants/media.constants';

const emit = defineEmits<IBKButtonNavigationGroup>();

const { t } = useI18n();

const props = withDefaults(defineProps<IPropsBKCarouselButtonNavigationGroup>(), {
  available: false,
  backgroundColor: 'var(--main-color-500)',
  backgroundPrepend: true,
  buttonDefaultText: defaultPriceOptions,
  loaderText: '',
  prepend: false,
  selected: false,
});

const { auth } = toRefs(props);

const defaultText = props.loaderText ? props.loaderText : t('caseOpenInProcess');

const bindPropsButtonAdded = computed<TBKButtonCarouselNavigation>(() =>
  Object.assign(defaultButtonAdded, props.buttonAdded),
);

const handlerSell = (): void => {
  emit('onSell');
};

const styles = computed<CSSProperties>(() => {
  const properties: CSSProperties = {};
  if (props.backgroundColor) properties.backgroundColor = props.backgroundColor;
  return properties;
});

const viewport = useViewport();

const loadingBtnFormPicker = computed(() => {
  // Адаптив для формы кнопки загрузки, в зависимости от значения viewport выбираем нужный пресет
  if (viewport.isLessThan(Breakpoints.md)) return loaderBtnSmallFormPreset;
  return viewport.isLessThan(Breakpoints.xl) ? loaderBtnMediumFormPreset : loaderBtnLargeFormPreset;
});
const loadingBtnTextSizePicker = computed(() => {
  // Адаптив для текста кнопки загрузки, в зависимости от значения viewport выбираем нужный пресет
  return viewport.isLessThan(Breakpoints.xl) ? loaderBtnSmallTextPreset : loaderBtnBigTextPreset;
});

// TODO Сделать нормальный лоадинг
// Сейчас пропс loading принимает IsPending из useAsyncData - true когда загружаются запросы
// из этого вытекает 2 проблемы
// 1 - компонент смонтировался, а данные еще не загрузились - будет "Идет открытие кейса"
// 2 - getDrop уже закончился, а рулетка еще не докрутилась - под конец прокрутки появятся кнопки "открыть"
// Зафиксил эти проблемы +-костылями

const isMounted = ref<boolean>(false);

onMounted(() => {
  setTimeout(() => {
    isMounted.value = true;
  }, 1500);
});
</script>

<style src="./BKCarouselButtonNavigationGroup.scss" scoped lang="scss"></style>

<style lang="scss">
@use 'assets/styles/media';

.carusel-button-navigation__item-list.non-available {
  @include media.media-breakpoint-down(md) {
    button:first-child {
      order: 1;
      width: 50%;
    }

    button:nth-child(2) {
      width: 100%;
    }

    button:nth-child(3) {
      order: 2;
      width: 50%;
    }
  }
}
</style>
