<template>
  <div class="drop-list-container" :class="{ 'drop-list-container__scroll': items.length > 4 }" :style="styles">
    <div v-if="items.length > 0" class="drop-list-container__inner">
      <div v-for="(itemData, index) in items" :key="index" class="drop-list-item">
        <slot name="item" :item-data="itemData" :index="index" />
      </div>
      <div ref="inventoryLast" class="drop-list-item__last" />
      <BKIntegrationInventoryLoader v-if="listLoading" />
    </div>
    <div v-else-if="!items.length && !listLoading" class="drop-list-container__empty">
      <slot name="empty" />
    </div>
    <div v-else class="drop-list-container__loader">
      <BKIntegrationInventoryLoader />
    </div>
  </div>
</template>
<script setup lang="ts">
import type {
  IBKIntegrationOldDropListEmits,
  IBKIntegrationOldDropListProps,
} from '~/features/bk/components/BKIntegrationOldDropList/BKIntegrationOldDropList.types';
import { smallOldItemSize } from '~/features/bk/components/BKIntegrationOldDropList/BKIntegrationOldDropList.data';
import BKIntegrationInventoryLoader from '~/features/bk/components/BKIntegrationInventoryLoader/BKIntegrationInventoryLoader.vue';

const props = withDefaults(defineProps<IBKIntegrationOldDropListProps>(), {
  backgroundColor: 'var(--main-color-400)',
  itemSize: () => smallOldItemSize,
  listLoading: false,
});

const emits = defineEmits<IBKIntegrationOldDropListEmits>();

const styles = computed(() => ({
  '--bg-color': props.backgroundColor,
  '--height': props.itemSize.height,
  '--width': props.itemSize.width,
}));
const inventoryLast = ref<HTMLElement | undefined>();

const observer = new IntersectionObserver((data: IntersectionObserverEntry[]) => {
  if (data[0].isIntersecting) emits('onIntersecting');
});
watch(inventoryLast, () => {
  if (!inventoryLast.value) return;
  observer.observe(inventoryLast.value);
});
</script>

<style scoped lang="scss" src="./BKIntegrationOldDropList.scss"></style>
